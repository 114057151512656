import { Injectable } from '@angular/core';
import { AircraftType, Airline, Airport, Flight, Tail, Document, UpdateDocumentDto } from '../models';
import { map, Observable, retry } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '@core/services/settings/settings.service';
import { ElectronicDocument, ElectronicDocumentIncDto } from '../../../weight-balance-data/weight-balance';

@Injectable({
	providedIn: 'root',
})
export class WeightBalanceMobileService {
	private apiWeightBalanceElectronicDocumentFlowUrl: string;
	private apiWeightBalanceUrl: string;

	constructor(private http: HttpClient, private settingsService: SettingsService) {
		settingsService.general.applicationConfig$.subscribe(config => {
			this.apiWeightBalanceUrl = config.apiWeightBalanceURL;
			this.apiWeightBalanceElectronicDocumentFlowUrl = config.apiWeightBalanceElectronicDocumentFlowUrl;
		});
	}

	setDefaultHttpHeader(requestId?): object {
		// Формирование заголовков для отслеживания запросов
		// X-Correlation-ID идентификатор пользовательской сессии
		// X-Request-ID идентификатор события / запроса
		const httpOptions = {};
		httpOptions['headers'] = {
			'Content-Type': 'application/json',
			'X-Correlation-ID': this.settingsService.general.userSessionUuid,
			'X-Request-ID': requestId === undefined ? this.settingsService.general.randomUuid : requestId,
		};
		return httpOptions;
	}

	getReferenceAircraftType(): Observable<AircraftType[]> {
		const httpOptions = this.setDefaultHttpHeader();
		return this.http
			.get<AircraftType[]>(this.apiWeightBalanceUrl + '/master_data/aircraft_types', httpOptions)
			.pipe(retry(1));
	}

	getReferenceAirline(): Observable<Airline[]> {
		const httpOptions = this.setDefaultHttpHeader();
		return this.http.get<Airline[]>(this.apiWeightBalanceUrl + '/master_data/airlines', httpOptions).pipe(retry(1));
	}

	getReferenceAirport(): Observable<Airport[]> {
		const httpOptions = this.setDefaultHttpHeader();
		return this.http.get<Airport[]>(this.apiWeightBalanceUrl + '/master_data/airports', httpOptions).pipe(retry(1));
	}

	getReferenceTail(): Observable<Tail[]> {
		const httpOptions = this.setDefaultHttpHeader();
		return this.http.get<Tail[]>(this.apiWeightBalanceUrl + '/master_data/tails', httpOptions).pipe(retry(1));
	}

	getFlights(workspace?, xRequestId?): Observable<Flight[]> {
		let url = '/flights';
		const params = [];
		const currentDate = new Date();
		// От текущего времени выбор рейса +- 12 часов
		// Первая итерация это -12 часов
		currentDate.setHours(currentDate.getHours() - 12);
		params.push('start=' + currentDate.toISOString());
		// Вторая итерация +24 часа, что бы компенсировать вычтенные 12 и добавить 12
		currentDate.setHours(currentDate.getHours() + 24);
		params.push('finish=' + currentDate.toISOString());

		params.push('cancelled=false');
		params.push('deleted=false');

		if (workspace !== null) {
			params.push('workspace=' + workspace);
		}

		if (params.length > 0) {
			url += '?' + params.join('&');
		}

		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http.get<Flight[]>(this.apiWeightBalanceUrl + url, httpOptions).pipe(retry(1));
	}

	getLoadSheet(flightId: number, edition: number, xRequestId?): Observable<Document> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Document>(
				this.apiWeightBalanceUrl + '/documents/' + flightId + '/editions/' + edition + '/loadsheet',
				httpOptions
			)
			.pipe(retry(1));
	}

	getLoadInstruction(flightId: number, edition: number, xRequestId?): Observable<Document> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<Document>(
				this.apiWeightBalanceUrl + '/documents/' + flightId + '/editions/' + edition + '/loading_instruction',
				httpOptions
			)
			.pipe(retry(1));
	}

	getAllElectronicDocumentByFlightList(ids: number[], xRequestId?): Observable<ElectronicDocument[]> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.get<ElectronicDocumentIncDto[]>(
				this.apiWeightBalanceElectronicDocumentFlowUrl + '/documents/flights?ids=' + ids.join(','),
				httpOptions
			)
			.pipe(
				map(documentArray => {
					const result = [];
					documentArray.forEach(item => {
						const document = new ElectronicDocument();
						document._id = item._id;
						document.flight_id = item.flight_id;
						if (item.edition_list.length > 0) {
							Object.assign(document, item.edition_list[item.edition_list.length - 1]);
						}
						result.push(document);
					});
					return result;
				}),
				retry(1)
			);
	}

	updateDocumentsStatus(updateDocumentDto: UpdateDocumentDto, xRequestId?): Observable<{ flight_id: number }> {
		const httpOptions = this.setDefaultHttpHeader(xRequestId);
		return this.http
			.post<{ flight_id: number }>(
				this.apiWeightBalanceElectronicDocumentFlowUrl + '/documents/update-status',
				JSON.stringify(updateDocumentDto),
				httpOptions
			)
			.pipe(retry(1));
	}
}
