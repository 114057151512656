import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ReferanceModuleRestApiService } from '../referance-module-data/referance-module-rest-api.service';
import {
  ReferanceAirport,
  ReferanceAirline,
  ReferanceMovement,
  ReferanceDelay,
  ReferanceSeason,
  ReferanceImport,
  ReferanceOrder,
  ReferanceRegion,
  ReferanceCountry,
  ReferanceCity,
  ReferanceAircraftType,
  ReferanceAircraftKind,
  ReferanceAircraftClass,
  ReferanceTail,
  ReferanceDelaySource,
  ReferanceRegularitie,
  ReferanceStand,
  ReferanceStandType,
  ReferanceTerminal
} from '../referance-module-data/referance';
import { NgForm } from '@angular/forms';
import { FileSaverService } from 'ngx-filesaver';
import * as Papa from 'papaparse';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from "@shared/models/module-name";

@Component({
             selector: 'app-referance-module',
             templateUrl: './referance-module.component.html',
             styleUrls: ['./referance-module.component.less']
           })
export class ReferanceModuleComponent implements OnInit {

  // Массивы для хранения данных с бэка
  private privateAirports: Array<ReferanceAirport> = [];
  private privateAirlines: Array<ReferanceAirline> = [];
  private privateAircraftTypes: Array<ReferanceAircraftType> = [];
  private privateMovements: Array<ReferanceMovement> = [];
  private privateDelays: Array<ReferanceDelay> = [];
  private privateDelaySources: Array<ReferanceDelaySource> = [];
  private privateSeasons: Array<ReferanceSeason> = [];
  private privateRegions: Array<ReferanceRegion> = [];
  private privateCountries: Array<ReferanceCountry> = [];
  private privateCities: Array<ReferanceCity> = [];
  private privateAircraftKinds: Array<ReferanceAircraftKind> = [];
  private privateAircraftClasses: Array<ReferanceAircraftClass> = [];
  private privateTails: Array<ReferanceTail> = [];
  private privateRegularities: Array<ReferanceRegularitie> = [];
  private privateStands: Array<ReferanceStand> = [];
  private privateStandTypes: Array<ReferanceStandType> = [];
  private privateTerminals: Array<ReferanceTerminal> = [];

  // Переменные для работы с одной записью справочника
  @Input() airport: ReferanceAirport = new ReferanceAirport();
  @Input() airline: ReferanceAirline = new ReferanceAirline();
  @Input() aircraftType: ReferanceAircraftType = new ReferanceAircraftType();
  @Input() movement: ReferanceMovement = new ReferanceMovement();
  @Input() delay: ReferanceDelay = new ReferanceDelay();
  @Input() delaySource: ReferanceDelaySource = new ReferanceDelaySource();
  @Input() season: ReferanceSeason = new ReferanceSeason();
  @Input() region: ReferanceRegion = new ReferanceRegion();
  @Input() country: ReferanceCountry = new ReferanceCountry();
  @Input() city: ReferanceCity = new ReferanceCity();
  @Input() aircraftKind: ReferanceAircraftKind = new ReferanceAircraftKind();
  @Input() aircraftClass: ReferanceAircraftClass = new ReferanceAircraftClass();
  @Input() tail: ReferanceTail = new ReferanceTail();
  @Input() regularitie: ReferanceRegularitie = new ReferanceRegularitie();
  @Input() stand: ReferanceStand = new ReferanceStand();
  @Input() standType: ReferanceStandType = new ReferanceStandType();
  @Input() terminal: ReferanceTerminal = new ReferanceTerminal();

  // Переменная для работы с импортируемыми записями из файла
  @Input() import: ReferanceImport = new ReferanceImport();
  @Input() order: ReferanceOrder = new ReferanceOrder();

  // Переменные диалоговых окон добавления/редактирования записей в справочники
  @ViewChild('airportForm') airportForm: NgForm;
  @ViewChild('airportFormFade') airportFormFade: any;
  @ViewChild('airlineForm') airlineForm: NgForm;
  @ViewChild('airlineFormFade') airlineFormFade: any;
  @ViewChild('aircraftTypeForm') aircraftTypeForm: NgForm;
  @ViewChild('aircraftTypeFormFade') aircraftTypeFormFade: any;
  @ViewChild('movementForm') movementForm: NgForm;
  @ViewChild('movementFormFade') movementFormFade: any;
  @ViewChild('delayForm') delayForm: NgForm;
  @ViewChild('delayFormFade') delayFormFade: any;
  @ViewChild('delaySourceForm') delaySourceForm: NgForm;
  @ViewChild('delaySourceFormFade') delaySourceFormFade: any;
  @ViewChild('seasonForm') seasonForm: NgForm;
  @ViewChild('seasonFormFade') seasonFormFade: any;
  @ViewChild('regionForm') regionForm: NgForm;
  @ViewChild('regionFormFade') regionFormFade: any;
  @ViewChild('countryForm') countryForm: NgForm;
  @ViewChild('countryFormFade') countryFormFade: any;
  @ViewChild('cityForm') cityForm: NgForm;
  @ViewChild('cityFormFade') cityFormFade: any;
  @ViewChild('aircraftKindForm') aircraftKindForm: NgForm;
  @ViewChild('aircraftKindFormFade') aircraftKindFormFade: any;
  @ViewChild('aircraftClassForm') aircraftClassForm: NgForm;
  @ViewChild('aircraftClassFormFade') aircraftClassFormFade: any;
  @ViewChild('tailForm') tailForm: NgForm;
  @ViewChild('tailFormFade') tailFormFade: any;
  @ViewChild('regularitieForm') regularitieForm: NgForm;
  @ViewChild('regularitieFormFade') regularitieFormFade: any;
  @ViewChild('standForm') standForm: NgForm;
  @ViewChild('standFormFade') standFormFade: any;
  @ViewChild('standTypeForm') standTypeForm: NgForm;
  @ViewChild('standTypeFormFade') standTypeFormFade: any;
  @ViewChild('terminalForm') terminalForm: NgForm;
  @ViewChild('terminalFormFade') terminalFormFade: any;

  @ViewChild('airportFormImport') airportFormImport: NgForm;
  @ViewChild('airportFormFadeImport') airportFormFadeImport: any;
  @ViewChild('importBindDataForm') importBindDataForm: NgForm;
  @ViewChild('importResultValidation') importResultValidation: ElementRef<HTMLElement>;

  // Текущая просматриваемая вкладка
  activeTab = 'tab-tails';
  // Текущее состояние отображения диалоговых окон
  // state-append режим добавления новой записи
  // state-update режим редактирования записи
  activeState = 'state-append';
  // Переменная содержащая описание ошибки возникающей при работе модуля
  errorMessage = '';
  // Тип ошибки: error, warning, info
  errorType = '';
  // Действие пользователя
  userAction = '';
  // Сохранение функции для подтверждения
  executableFunction;

  // Переменная для выставления флага включения/выключения обработки заголовков
  // при импорте данных
  @Input() importHeaderParse = true;
  // Флаг отображения записей содержащих ошибки при валидации данных импорта
  @Input() showOnlyRecordsWithErrors = false;
  // Разделитель данных, пустое означает автоопределение
  @Input() importDelimiter = '';
  importDelimiterArray: any[] = [['', 'Auto'], [',', ','], ['\t', 'Tab'], ['|', '|'], [';', ';']];
  // Переход на новую сторку, пустое означает автоопределение
  @Input() importNewLine = undefined;
  importNewLineArray: any[] = [['', 'Auto'], ['\r\n', '\\r\\n'], ['\n', '\\n'], ['\r', '\\r']];
  // Счетчик шагов выполнения импорта
  importStep = 1;
  // Короткая анотация к шагу импорта
  importStepNote = ['',
    'Upload data file',
    'Preview file parsing results',
    'Field mapping',
    'Validation of imported data',
    'Import results'];
  // Текущая страница на вкладке Аэропорты
  currentPage = 1;
  itemsPerPage = 15;
  // Текущая страница на просмотре разобранных данных при настройке импорта
  importPreviewDataPage = 1;
  // Количество записей на странице импорта
  importItemsPerPage = 5;
  // Текущая страница подготовленных данных для импорта
  importDataPage = 1;
  //  Отображение текущей плохой записи при переходе по плохим записям
  importCurrentShowBadRow = -1;
  importLastShowBadRow = -1;
  // Номер страницы на которой был пользователь
  importDataPagePrevious = 0;
  // Флаг отображеия анимации загрузки данных при импорте
  importLoadAnimation = false;
  // Флаг инициализации проверки привязанности обязательных полей
  importBindingCheck = false;
  // Индекс редактируемой записи на форме валидации для оперативной правки данных
  importDataEditRowIndex = -1;
  importPopoverClickRow = -1;

  // Блок переменных для работы ng-select списков
  // Размер отображаемых данных в выпадающем списке
  referanceBufferSize = 50;
  // Количество элементов до конца списка, перед загрузкой новой порции
  numberOfItemsFromEndBeforeFetchingMore = 10;

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Типов Воздушных Судов
  aircraftTypesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  aircraftTypesBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Стран
  countriesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  countriesBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Городов
  citiesLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  citiesBuffer = [];

  // Статус загрузки/обновления справочника для отображения анимации
  // в компоненте ng-select для Регионов
  regionsLoading = false;
  // Подгружаемый список для вывода в выпадающий список
  regionsBuffer = [];

  // Идентификатор загрузки
  loading = false;

  // Переменные для полей поиска по таблицам
  @Input() filterIata = '';
  @Input() filterIcao = '';
  @Input() filterName = '';
  @Input() filterType = '';

  constructor(
    public restApi: ReferanceModuleRestApiService,
    private globalI18n: GlobalI18n,
    private fileSaverService: FileSaverService
  ) {
  }

  ngOnInit() {
    this.loadAircraftTypes();
    this.loadTails();
  }

  // Функции прокрутки для Типов Воздушных Судов
  onScrollAircraftTypes({ end }) {
    if (this.aircraftTypesLoading || this.privateAircraftTypes.length <= this.aircraftTypesBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.aircraftTypesBuffer.length) {
        this.aircraftTypesFetchMore();
    }
  }

  onScrollAircraftTypesToEnd() {
    this.aircraftTypesFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Типов Воздушных Судов
  private aircraftTypesFetchMore() {
    const len = this.aircraftTypesBuffer.length;
    const more = this.privateAircraftTypes.slice(len, this.referanceBufferSize + len);
    this.aircraftTypesLoading = true;
    this.aircraftTypesBuffer = this.privateAircraftTypes.concat(more);
    this.aircraftTypesLoading = false;
  }

  get aircraftsForSelectList(): ReferanceAircraftType[] {
    return this.privateAircraftTypes;
  }

  // Функции прокрутки для Стран
  onScrollCountries({ end }) {
    if (this.countriesLoading || this.privateCountries.length <= this.countriesBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.countriesBuffer.length) {
        this.countriesFetchMore();
    }
  }

  onScrollCountriesToEnd() {
    this.countriesFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Стран
  private countriesFetchMore() {
    const len = this.countriesBuffer.length;
    const more = this.privateCountries.slice(len, this.referanceBufferSize + len);
    this.countriesLoading = true;
    this.countriesBuffer = this.privateCountries.concat(more);
    this.countriesLoading = false;
  }

  get countriesForSelectList(): ReferanceCountry[] {
    return this.privateCountries;
  }

  // Функции прокрутки для Городов
  onScrollCities({ end }) {
    if (this.citiesLoading || this.privateCities.length <= this.citiesBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.citiesBuffer.length) {
        this.citiesFetchMore();
    }
  }

  onScrollCitiesToEnd() {
    this.citiesFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Типов Воздушных Судов
  private citiesFetchMore() {
    const len = this.citiesBuffer.length;
    const more = this.privateCities.slice(len, this.referanceBufferSize + len);
    this.citiesLoading = true;
    this.citiesBuffer = this.privateCities.concat(more);
    this.citiesLoading = false;
  }

  get citiesForSelectList(): ReferanceCity[] {
    return this.privateCities;
  }

  // Функции прокрутки для Регионов
  onScrollRegions({ end }) {
    if (this.regionsLoading || this.privateRegions.length <= this.regionsBuffer.length) {
        return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.regionsBuffer.length) {
        this.regionsFetchMore();
    }
  }

  onScrollRegionsToEnd() {
    this.regionsFetchMore();
  }

  // Функция загрузки списка для отображения в ng-select Регионов
  private regionsFetchMore() {
    const len = this.regionsBuffer.length;
    const more = this.privateRegions.slice(len, this.referanceBufferSize + len);
    this.regionsLoading = true;
    this.regionsBuffer = this.privateRegions.concat(more);
    this.regionsLoading = false;
  }

  get regionsForSelectList(): ReferanceRegion[] {
    return this.privateRegions;
  }

  // Airports
  loadAirports() {
    console.log('load filters');
    this.loading = true;
    return this.restApi.getAirports().then((data: ReferanceAirport[]) => {
      this.privateAirports.length = 0;
      for (const item of data) {
        const airport = new ReferanceAirport();
        Object.assign(airport, item);
        this.privateAirports.push(airport);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get airports(): ReferanceAirport[] {
    return this.privateAirports
      .filter(item => {
        // При установленных фильтрах необходимо сбросить
        // пагинацию на 1 страницу для отображения результатов
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.iata.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.icao.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadAirport(id: number) {
    return this.restApi.getAirport(id).then((data: ReferanceAirport) => {
      this.activeState = 'state-update';
      const airport = new ReferanceAirport();
      Object.assign(airport, data);
      airport.iata = airport.iata.trim();
      airport.icao = airport.icao.trim();
      this.airport = airport;
    }, err => this.displayError(err));
  }

  createAirport() {
    this.activeState = 'state-append';
    this.airport = new ReferanceAirport();
    this.airportForm.reset();
    console.log(this.airport);
  }

  addAirport() {
    if ((this.airport.dtRangeStart) && (this.airport.dtRangeFinish) &&
      (this.airport.dtRangeStart >= this.airport.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.airportForm.form.markAllAsTouched();
      if (this.airportForm.valid) {
        this.loading = true;
        this.restApi.createAirport(this.airport).subscribe((data: {}) => {
          this.loadAirports();
          this.loading = false;
          this.airportFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateAirport(id: any) {
    if ((this.airport.dtRangeStart) && (this.airport.dtRangeFinish) &&
      (this.airport.dtRangeStart >= this.airport.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.airportForm.form.markAllAsTouched();
      if (this.airportForm.valid) {
        this.loading = true;
        console.log(JSON.stringify(this.airport));

        this.restApi.updateAirport(id, this.airport).then(data => {
          this.loadAirports();
          this.loading = false;
          this.airportFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteAirport(id: any) {
    this.loading = true;
    this.airport.dtRangeFinish = new Date();
    this.restApi.updateAirport(id, this.airport).then(data => {
      this.clearErrorMess();
      this.loadAirports();
      this.loading = false;
      this.airportFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Airlines
  loadAirlines() {
    console.log('load airlines');
    this.loading = true;
    this.errorMessage = '';
    return this.restApi.getAirlines().subscribe((data: ReferanceAirline[]) => {
      this.privateAirlines.length = 0;
      for (const item of data) {
        const airline = new ReferanceAirline();
        Object.assign(airline, item);
        this.privateAirlines.push(airline);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get airlines(): ReferanceAirline[] {
    return this.privateAirlines
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.iata.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.icao.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadAirline(id: number) {
    console.log('load airline' + id);
    return this.restApi.getAirline(id).subscribe((data: ReferanceAirline) => {
      this.activeState = 'state-update';
      const airline = new ReferanceAirline();
      Object.assign(airline, data);
      this.airline = airline;
      airline.iata = airline.iata.trim();
      airline.icao = airline.icao.trim();
      console.log(this.airline);
    }, err => this.displayError(err));
  }

  createAirline() {
    console.log(this.airline);
    this.activeState = 'state-append';
    this.airline = new ReferanceAirline();
    this.airlineForm.reset();
    console.log(this.airline);
  }

  addAirline() {
    if ((this.airline.dtRangeStart) && (this.airline.dtRangeFinish) && (this.airline.dtRangeStart >= this.airline.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.airlineForm.form.markAllAsTouched();
      if (this.airlineForm.valid) {
        this.loading = true;
        this.restApi.createAirline(this.airline).subscribe((data: {}) => {
          this.loadAirlines();
          this.loading = false;
          this.airlineFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateAirline(id: any) {
    if ((this.airline.dtRangeStart) && (this.airline.dtRangeFinish) &&
      (this.airline.dtRangeStart >= this.airline.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.airlineForm.form.markAllAsTouched();
      if (this.airlineForm.valid) {
        this.loading = true;
        this.restApi.updateAirline(id, this.airline).subscribe(data => {
          this.loadAirlines();
          this.loading = false;
          this.airlineFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteAirline(id: any) {
    this.loading = true;
    this.airline.dtRangeFinish = new Date();
    this.restApi.updateAirline(id, this.airline).subscribe(data => {
      this.clearErrorMess();
      this.loadAirlines();
      this.loading = false;
      this.airlineFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Aircraft types
  loadAircraftTypes() {
    console.log('load aircraft types');
    this.aircraftTypesLoading = true;
    this.loading = true;
    return this.restApi.getAircraftTypes().subscribe((data: ReferanceAircraftType[]) => {
      this.privateAircraftTypes.length = 0;
      for (const item of data) {
        const aircraftType = new ReferanceAircraftType();
        Object.assign(aircraftType, item);
        // Вмесето this.privateAircraftTypes.push(aircraftType) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateAircraftTypes = [...this.privateAircraftTypes, aircraftType];
      }
      this.aircraftTypesLoading = false;
      // Загрука первой партии элементов для отображания в выпадающем списке
      this.aircraftTypesBuffer.length = 0;
      this.aircraftTypesBuffer = this.privateAircraftTypes.slice(0, this.referanceBufferSize);
      this.loading = false;
    }, err => this.displayError(err));
  }

  get aircraftTypes(): ReferanceAircraftType[] {
    return this.privateAircraftTypes
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.iata.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.icao.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadAircraftType(id: number) {
    console.log('load aircraft type' + id);
    return this.restApi.getAircraftType(id).subscribe((data: ReferanceAircraftType) => {
      this.activeState = 'state-update';
      const aircraftType = new ReferanceAircraftType();
      Object.assign(aircraftType, data);
      this.aircraftType = aircraftType;
    }, err => this.displayError(err));
  }

  createAircraftType() {
    this.activeState = 'state-append';
    this.aircraftType = new ReferanceAircraftType();
    this.aircraftTypeForm.reset();
  }

  addAircraftType() {
    if ((this.aircraftType.dtRangeStart) && (this.aircraftType.dtRangeFinish) &&
      (this.aircraftType.dtRangeStart >= this.aircraftType.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.aircraftTypeForm.form.markAllAsTouched();
      if (this.aircraftTypeForm.valid) {
        this.loading = true;
        this.restApi.createAircraftType(this.aircraftType).subscribe((data: {}) => {
          this.loadAircraftTypes();
          this.loading = false;
          this.aircraftTypeFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateAircraftType(id: any) {
    if ((this.aircraftType.dtRangeStart) && (this.aircraftType.dtRangeFinish) &&
      (this.aircraftType.dtRangeStart >= this.aircraftType.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.aircraftTypeForm.form.markAllAsTouched();
      if (this.aircraftTypeForm.valid) {
        this.loading = true;
        this.restApi.updateAircraftType(id, this.aircraftType).subscribe(data => {
          this.loadAircraftTypes();
          this.loading = false;
          this.aircraftTypeFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteAircraftType(id: any) {
    this.loading = true;
    this.aircraftType.dtRangeFinish = new Date();
    this.restApi.updateAircraftType(id, this.aircraftType).subscribe(data => {
      this.clearErrorMess();
      this.loadAircraftTypes();
      this.loading = false;
      this.aircraftTypeFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Movements
  loadMovements() {
    console.log('load movements');
    this.loading = true;
    return this.restApi.getMovements().subscribe((data: ReferanceMovement[]) => {
      this.privateMovements.length = 0;
      for (const item of data) {
        const movement = new ReferanceMovement();
        Object.assign(movement, item);
        this.privateMovements.push(movement);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get movements(): ReferanceMovement[] {
    return this.privateMovements
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.code.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.code.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadMovement(id: number) {
    console.log('load movement ' + id);
    return this.restApi.getMovement(id).subscribe((data: ReferanceMovement) => {
      this.activeState = 'state-update';
      const movement = new ReferanceMovement();
      Object.assign(movement, data);
      this.movement = movement;
    }, err => this.displayError(err));
  }

  createMovement() {
    this.activeState = 'state-append';
    this.movement = new ReferanceMovement();
    this.movementForm.reset();
  }

  addMovement() {
    if ((this.movement.dtRangeStart) && (this.movement.dtRangeFinish) &&
      (this.movement.dtRangeStart >= this.movement.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.movementForm.form.markAllAsTouched();
      if (this.movementForm.valid) {
        this.loading = true;
        this.restApi.createMovement(this.movement).subscribe((data: {}) => {
          this.loadMovements();
          this.loading = false;
          this.movementFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateMovement(id: any) {
    if ((this.movement.dtRangeStart) && (this.movement.dtRangeFinish) &&
      (this.movement.dtRangeStart >= this.movement.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.movementForm.form.markAllAsTouched();
      if (this.movementForm.valid) {
        this.loading = true;
        this.restApi.updateMovement(id, this.movement).subscribe(data => {
          this.loadMovements();
          this.loading = false;
          this.movementFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteMovement(id: any) {
    this.loading = true;
    this.movement.dtRangeFinish = new Date();
    this.restApi.updateMovement(id, this.movement).subscribe(data => {
      this.clearErrorMess();
      this.loadMovements();
      this.loading = false;
      this.movementFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Delays
  loadDelays() {
    console.log('load delays');
    this.loading = true;
    return this.restApi.getDelays().subscribe((data: ReferanceDelay[]) => {
      this.privateDelays.length = 0;
      for (const item of data) {
        const delay = new ReferanceDelay();
        Object.assign(delay, item);
        this.privateDelays.push(delay);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get delays(): ReferanceDelay[] {
    return this.privateDelays
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadDelay(id: number) {
    console.log('load delay ' + id);
    return this.restApi.getDelay(id).subscribe((data: ReferanceDelay) => {
      this.activeState = 'state-update';
      const delay = new ReferanceDelay();
      Object.assign(delay, data);
      this.delay = delay;
    }, err => this.displayError(err));
  }

  createDelay() {
    this.activeState = 'state-append';
    this.delay = new ReferanceDelay();
    this.delayForm.reset();
  }

  addDelay() {
    if ((this.delay.dtRangeStart) && (this.delay.dtRangeFinish) && (this.delay.dtRangeStart >= this.delay.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.delayForm.form.markAllAsTouched();
      if (this.delayForm.valid) {
        this.loading = true;
        this.restApi.createDelay(this.delay).subscribe((data: {}) => {
          this.loadDelays();
          this.loading = false;
          this.delayFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateDelay(id: any) {
    if ((this.delay.dtRangeStart) && (this.delay.dtRangeFinish) &&
      (this.delay.dtRangeStart >= this.delay.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.delayForm.form.markAllAsTouched();
      if (this.delayForm.valid) {
        this.loading = true;
        this.restApi.updateDelay(id, this.delay).subscribe(data => {
          this.loadDelays();
          this.loading = false;
          this.delayFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteDelay(id: any) {
    this.loading = true;
    this.delay.dtRangeFinish = new Date();
    this.restApi.updateDelay(id, this.delay).subscribe(data => {
      this.clearErrorMess();
      this.loadDelays();
      this.loading = false;
      this.delayFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Delay Sources
  loadDelaySources() {
    console.log('load delays');
    this.loading = true;
    return this.restApi.getDelaySources().subscribe((data: ReferanceDelaySource[]) => {
      this.privateDelaySources.length = 0;
      for (const item of data) {
        const delay = new ReferanceDelaySource();
        Object.assign(delay, item);
        this.privateDelaySources.push(delay);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get delaySources(): ReferanceDelaySource[] {
    return this.privateDelaySources
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadDelaySource(id: number) {
    console.log('load delay ' + id);
    return this.restApi.getDelaySource(id).subscribe((data: ReferanceDelaySource) => {
      this.activeState = 'state-update';
      const delay = new ReferanceDelaySource();
      Object.assign(delay, data);
      this.delaySource = delay;
    }, err => this.displayError(err));
  }

  createDelaySource() {
    console.log(this.delaySource);
    this.activeState = 'state-append';
    this.delaySource = new ReferanceDelaySource();
    this.delaySourceForm.reset();
    console.log(this.delaySource);
  }

  addDelaySource() {
    if ((this.delaySource.dtRangeStart) &&
        (this.delaySource.dtRangeFinish) &&
        (this.delaySource.dtRangeStart >= this.delaySource.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.delaySourceForm.form.markAllAsTouched();
      if (this.delaySourceForm.valid) {
        this.loading = true;
        this.restApi.createDelaySource(this.delaySource).subscribe((data: {}) => {
          this.loadDelaySources();
          this.loading = false;
          this.delaySourceFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateDelaySource(id: any) {
    if ((this.delaySource.dtRangeStart) && (this.delaySource.dtRangeFinish) &&
      (this.delaySource.dtRangeStart >= this.delaySource.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.delaySourceForm.form.markAllAsTouched();
      if (this.delaySourceForm.valid) {
        this.loading = true;
        this.restApi.updateDelaySource(id, this.delaySource).subscribe(data => {
          this.loadDelaySources();
          this.loading = false;
          this.delaySourceFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteDelaySource(id: any) {
    this.loading = true;
    this.delaySource.dtRangeFinish = new Date();
    this.restApi.updateDelaySource(id, this.delaySource).subscribe(data => {
      this.clearErrorMess();
      this.loadDelaySources();
      this.loading = false;
      this.delaySourceFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Seasons
  loadSeasons() {
    console.log('load seasons');
    this.loading = true;
    return this.restApi.getSeasons().subscribe((data: ReferanceSeason[]) => {
      this.privateSeasons.length = 0;
      for (const item of data) {
        const season = new ReferanceSeason();
        Object.assign(season, item);
        this.privateSeasons.push(season);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get seasons(): ReferanceSeason[] {
    return this.privateSeasons
      .filter(item => {
        if (this.filterType.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterType == '' || ('Summer'.toLowerCase().indexOf(this.filterType.toLowerCase()) != -1 && item.type == 1) ||
          ('Winter'.toLowerCase().indexOf(this.filterType.toLowerCase()) != -1 && item.type == 0));
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadSeason(id: number) {
    console.log('load season ' + id);
    return this.restApi.getSeason(id).subscribe((data: ReferanceSeason) => {
      console.log(JSON.stringify(data));
      this.activeState = 'state-update';
      const season = new ReferanceSeason();
      Object.assign(season, data);
      this.season = season;
    }, err => this.displayError(err));
  }

  createSeason() {
    this.activeState = 'state-append';
    this.season = new ReferanceSeason();
    this.seasonForm.reset();
  }

  addSeason() {
    if ((this.season.dtRangeStart) && (this.season.dtRangeFinish) &&
      (this.season.dtRangeStart >= this.season.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else if ((this.season.dtSeasonStart) && (this.season.dtSeasonFinish) &&
      (this.season.dtSeasonStart >= this.season.dtSeasonFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'seasonDateError');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.seasonForm.form.markAllAsTouched();
      if (this.seasonForm.valid) {
        this.loading = true;
        this.restApi.createSeason(this.season).subscribe((data: {}) => {
          this.loadSeasons();
          this.loading = false;
          this.seasonFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateSeason(id: any) {
    if (this.season.dtRangeStart >= this.season.dtRangeFinish) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else if (this.season.dtSeasonStart >= this.season.dtSeasonFinish) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'seasonDateError');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.seasonForm.form.markAllAsTouched();
      if (this.seasonForm.valid) {
        this.loading = true;
        this.restApi.updateSeason(id, this.season).subscribe(data => {
          this.loadSeasons();
          this.loading = false;
          this.seasonFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteSeason(id: any) {
    this.loading = true;
    this.season.dtRangeFinish = new Date();
    this.restApi.updateSeason(id, this.season).subscribe(data => {
      this.clearErrorMess();
      this.loadSeasons();
      this.loading = false;
      this.seasonFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Regions
  loadRegions() {
    console.log('load regions');
    this.regionsLoading = true;
    this.loading = true;
    return this.restApi.getRegions().subscribe((data: ReferanceRegion[]) => {
      this.privateRegions.length = 0;
      for (const item of data) {
        const region = new ReferanceRegion();
        Object.assign(region, item);
        // Вмесето this.privateRegions.push(region) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateRegions = [...this.privateRegions, region];
      }
      this.regionsLoading = false;
      // Загрука первой партии элементов для отображания в выпадающем списке
      this.regionsBuffer.length = 0;
      this.regionsBuffer = this.privateRegions.slice(0, this.referanceBufferSize);
      this.loading = false;
    }, err => this.displayError(err));
  }

  get regions(): ReferanceRegion[] {
    return this.privateRegions
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.iata.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.icao.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadRegion(id: number) {
    console.log('load region ' + id);
    return this.restApi.getRegion(id).subscribe((data: ReferanceRegion) => {
      this.activeState = 'state-update';
      const region = new ReferanceRegion();
      Object.assign(region, data);
      this.region = region;
    }, err => this.displayError(err));
  }

  createRegion() {
    this.activeState = 'state-append';
    this.region = new ReferanceRegion();
    this.regionForm.reset();
  }

  addRegion() {
    if ((this.region.dtRangeStart) && (this.region.dtRangeFinish) &&
      (this.region.dtRangeStart >= this.region.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.regionForm.form.markAllAsTouched();
      if (this.regionForm.valid) {
        this.loading = true;
        this.restApi.createRegion(this.region).subscribe((data: {}) => {
          this.loadRegions();
          this.loading = false;
          this.regionFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateRegion(id: any) {
    if ((this.region.dtRangeStart) && (this.region.dtRangeFinish) &&
      (this.region.dtRangeStart >= this.region.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.regionForm.form.markAllAsTouched();
      if (this.regionForm.valid) {
        this.loading = true;
        this.restApi.updateRegion(id, this.region).subscribe(data => {
          this.loadRegions();
          this.loading = false;
          this.regionFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteRegion(id: any) {
    this.loading = true;
    this.region.dtRangeFinish = new Date();
    this.restApi.updateRegion(id, this.region).subscribe(data => {
      this.clearErrorMess();
      this.loadRegions();
      this.loading = false;
      this.regionFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Countries
  loadCountries() {
    console.log('load countries');
    this.countriesLoading = true;
    this.loading = true;
    return this.restApi.getCountries().subscribe((data: ReferanceCountry[]) => {
      this.privateCountries.length = 0;
      for (const item of data) {
        const country = new ReferanceCountry();
        Object.assign(country, item);
        // Вмесето this.privateCountries.push(country) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateCountries = [...this.privateCountries, country];
      }
      this.countriesLoading = false;
      // Загрука первой партии элементов для отображания в выпадающем списке
      this.countriesBuffer.length = 0;
      this.countriesBuffer = this.privateCountries.slice(0, this.referanceBufferSize);
      this.loading = false;
    }, err => this.displayError(err));
  }

  get countries(): ReferanceCountry[] {
    return this.privateCountries
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.iata.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.icao.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadCountry(id: number) {
    console.log('load country ' + id);
    return this.restApi.getCountry(id).subscribe((data: ReferanceCountry) => {
      this.activeState = 'state-update';
      const country = new ReferanceCountry();
      Object.assign(country, data);
      this.country = country;
      console.log(JSON.stringify(this.country));
    }, err => this.displayError(err));
  }

  createCountry() {
    this.activeState = 'state-append';
    this.country = new ReferanceCountry();
    this.countryForm.reset();
  }

  addCountry() {
    if ((this.country.dtRangeStart) && (this.country.dtRangeFinish) &&
      (this.country.dtRangeStart >= this.country.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.countryForm.form.markAllAsTouched();
      if (this.countryForm.valid) {
        this.loading = true;
        this.restApi.createCountry(this.country).subscribe((data: {}) => {
          this.loadCountries();
          this.loading = false;
          this.countryFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateCountry(id: any) {
    if ((this.country.dtRangeStart) && (this.country.dtRangeFinish) &&
      (this.country.dtRangeStart >= this.country.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.countryForm.form.markAllAsTouched();
      if (this.countryForm.valid) {
        this.loading = true;
        this.restApi.updateCountry(id, this.country).subscribe(data => {
          this.loadCountries();
          this.loading = false;
          this.countryFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteCountry(id: any) {
    this.loading = true;
    this.country.dtRangeFinish = new Date();
    this.restApi.updateCountry(id, this.country).subscribe(data => {
      this.clearErrorMess();
      this.loadCountries();
      this.loading = false;
      this.countryFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Cities
  loadCities() {
    console.log('load cities');
    this.citiesLoading = true;
    this.loading = true;
    return this.restApi.getCities().subscribe((data: ReferanceCity[]) => {
      this.privateCities.length = 0;
      for (const item of data) {
        const city = new ReferanceCity();
        Object.assign(city, item);
        // Вмесето this.privateCities.push(city) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateCities = [...this.privateCities, city];
      }
      this.citiesLoading = false;
      // Загрука первой партии элементов для отображания в выпадающем списке
      this.citiesBuffer.length = 0;
      this.citiesBuffer = this.privateCities.slice(0, this.referanceBufferSize);
      this.loading = false;
    }, err => this.displayError(err));
  }

  get cities(): ReferanceCity[] {
    return this.privateCities
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterIata == '' || item.iata.toLowerCase().indexOf(this.filterIata.toLowerCase()) != -1) &&
          (this.filterIcao == '' || item.icao.toLowerCase().indexOf(this.filterIcao.toLowerCase()) != -1) &&
          (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadCity(id: number) {
    console.log('load city ' + id);
    return this.restApi.getCity(id).subscribe((data: ReferanceCity) => {
      this.activeState = 'state-update';
      const city = new ReferanceCity();
      Object.assign(city, data);
      this.city = city;
      console.log(JSON.stringify(this.city));
    }, err => this.displayError(err));
  }

  createCity() {
    this.activeState = 'state-append';
    this.city = new ReferanceCity();
    this.cityForm.reset();
  }

  addCity() {
    console.log(JSON.stringify(this.city));
    if ((this.city.dtRangeStart) && (this.city.dtRangeFinish) &&
      (this.city.dtRangeStart >= this.city.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.cityForm.form.markAllAsTouched();
      if (this.cityForm.valid) {
        this.loading = true;
        this.restApi.createCity(this.city).subscribe((data: {}) => {
          this.loadCities();
          this.loading = false;
          this.cityFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateCity(id: any) {
    if ((this.city.dtRangeStart) && (this.city.dtRangeFinish) &&
      (this.city.dtRangeStart >= this.city.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.cityForm.form.markAllAsTouched();
      if (this.cityForm.valid) {
        this.loading = true;
        this.restApi.updateCity(id, this.city).subscribe(data => {
          this.loadCities();
          this.loading = false;
          this.cityFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteCity(id: any) {
    this.loading = true;
    this.city.dtRangeFinish = new Date();
    this.restApi.updateCity(id, this.city).subscribe(data => {
      this.clearErrorMess();
      this.loadCities();
      this.loading = false;
      this.cityFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Aircraft Kinds
  loadAircraftKinds() {
    console.log('load Aircraft Kinds');
    this.loading = true;
    return this.restApi.getAircraftKinds().subscribe((data: ReferanceAircraftKind[]) => {
      this.privateAircraftKinds.length = 0;
      for (const item of data) {
        const kind = new ReferanceAircraftKind();
        Object.assign(kind, item);
        // Вмесето this.privateAircraftKinds.push(kind) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateAircraftKinds = [...this.privateAircraftKinds, kind];
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  /**
   * Функция вывода списка видов воздушных судов для ng-select
   */
  get aircraftKindsForList(): ReferanceAircraftKind[] {
    return this.privateAircraftKinds;
  }

  get aircraftKinds(): ReferanceAircraftKind[] {
    return this.privateAircraftKinds
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadAircraftKind(id: number) {
    console.log('load kind ' + id);
    return this.restApi.getAircraftKind(id).subscribe((data: ReferanceAircraftKind) => {
      this.activeState = 'state-update';
      const kind = new ReferanceAircraftKind();
      Object.assign(kind, data);
      this.aircraftKind = kind;
      console.log(JSON.stringify(this.aircraftKind));
    }, err => this.displayError(err));
  }

  createAircraftKind() {
    this.activeState = 'state-append';
    this.aircraftKind = new ReferanceAircraftKind();
    this.aircraftKindForm.reset();
  }

  addAircraftKind() {
    console.log(JSON.stringify(this.aircraftKind));
    if ((this.aircraftKind.dtRangeStart) && (this.aircraftKind.dtRangeFinish) &&
      (this.aircraftKind.dtRangeStart >= this.aircraftKind.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.aircraftKindForm.form.markAllAsTouched();
      if (this.aircraftKindForm.valid) {
        this.loading = true;
        this.restApi.createAircraftKind(this.aircraftKind).subscribe((data: {}) => {
          this.loadAircraftKinds();
          this.loading = false;
          this.aircraftKindFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateAircraftKind(id: any) {
    if ((this.aircraftKind.dtRangeStart) && (this.aircraftKind.dtRangeFinish) &&
      (this.aircraftKind.dtRangeStart >= this.aircraftKind.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.aircraftKindForm.form.markAllAsTouched();
      if (this.aircraftKindForm.valid) {
        this.loading = true;
        this.restApi.updateAircraftKind(id, this.aircraftKind).subscribe(data => {
          this.loadAircraftKinds();
          this.loading = false;
          this.aircraftKindFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteAircraftKind(id: any) {
    this.loading = true;
    this.aircraftKind.dtRangeFinish = new Date();
    this.restApi.updateAircraftKind(id, this.aircraftKind).subscribe(data => {
      this.clearErrorMess();
      this.loadAircraftKinds();
      this.loading = false;
      this.aircraftKindFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Aircraft Classes
  loadAircraftClasses() {
    console.log('load Aircraft Classes');
    this.loading = true;
    return this.restApi.getAircraftClasses().subscribe((data: ReferanceAircraftClass[]) => {
      this.privateAircraftClasses.length = 0;
      for (const item of data) {
        const aircraftClass = new ReferanceAircraftClass();
        Object.assign(aircraftClass, item);
        // Вмесето this.privateAircraftClasses.push(aircraftClass) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateAircraftClasses = [...this.privateAircraftClasses, aircraftClass];
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  /**
   * Функция вывода списка видов воздушных судов для ng-select
   */
  get aircraftClassesForList(): ReferanceAircraftClass[] {
    return this.privateAircraftClasses;
  }

  get aircraftClasses(): ReferanceAircraftClass[] {
    return this.privateAircraftClasses
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadAircraftClass(id: number) {
    console.log('load class ' + id);
    return this.restApi.getAircraftClass(id).subscribe((data: ReferanceAircraftClass) => {
      this.activeState = 'state-update';
      const aircraftClass = new ReferanceAircraftClass();
      Object.assign(aircraftClass, data);
      this.aircraftClass = aircraftClass;
      console.log(JSON.stringify(this.aircraftClass));
    }, err => this.displayError(err));
  }

  createAircraftClass() {
    this.activeState = 'state-append';
    this.aircraftClass = new ReferanceAircraftClass();
    this.aircraftClassForm.reset();
  }

  addAircraftClass() {
    console.log(JSON.stringify(this.aircraftClass));
    if ((this.aircraftClass.dtRangeStart) && (this.aircraftClass.dtRangeFinish) &&
      (this.aircraftClass.dtRangeStart >= this.aircraftClass.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.aircraftClassForm.form.markAllAsTouched();
      if (this.aircraftClassForm.valid) {
        this.loading = true;
        this.restApi.createAircraftClass(this.aircraftClass).subscribe((data: {}) => {
          this.loadAircraftClasses();
          this.loading = false;
          this.aircraftClassFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateAircraftClass(id: any) {
    if ((this.aircraftClass.dtRangeStart) && (this.aircraftClass.dtRangeFinish) &&
      (this.aircraftClass.dtRangeStart >= this.aircraftClass.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.aircraftClassForm.form.markAllAsTouched();
      if (this.aircraftClassForm.valid) {
        this.loading = true;
        this.restApi.updateAircraftClass(id, this.aircraftClass).subscribe(data => {
          this.loadAircraftClasses();
          this.loading = false;
          this.aircraftClassFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteAircraftClass(id: any) {
    this.loading = true;
    this.aircraftClass.dtRangeFinish = new Date();
    this.restApi.updateAircraftClass(id, this.aircraftClass).subscribe(data => {
      this.clearErrorMess();
      this.loadAircraftClasses();
      this.loading = false;
      this.aircraftClassFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Tails
  loadTails() {
    console.log('load tails');
    this.loading = true;
    return this.restApi.getTails().then((data: ReferanceTail[]) => {
      this.privateTails.length = 0;
      for (const item of data) {
        const type = new ReferanceTail();
        Object.assign(type, item);
        this.privateTails.push(type);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get tails(): ReferanceTail[] {
    return this.privateTails
    .filter(item => {
      if (this.filterIata.length !== 0 || this.filterIcao.length !== 0 || this.filterName.length !== 0) {
        // Если нет запомненой страницы, записываем текущую
        this.setSelectedFirst();
      } else {
        // Фильтры не используются
        // Если был переход с другой страницы, переход на нее обратно
        this.setSelectedPage();
      }
      return (this.filterName == '' || item.tail.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
    })
    .sort((a, b) => this.sortObject(a, b));
  }

  loadTail(id: number) {
    console.log('load kind ' + id);
    return this.restApi.getTail(id).then((data: ReferanceTail) => {
      this.activeState = 'state-update';
      const tail = new ReferanceTail();
      Object.assign(tail, data);
      this.tail = tail;
      console.log(JSON.stringify(this.tail));
    }, err => this.displayError(err));
  }

  createTail() {
    this.activeState = 'state-append';
    this.tail = new ReferanceTail();
    this.tailForm.reset();
  }

  addTail() {
    console.log(JSON.stringify(this.tail));
    if ((this.tail.dtRangeStart) && (this.tail.dtRangeFinish) &&
      (this.tail.dtRangeStart >= this.tail.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.tailForm.form.markAllAsTouched();
      if (this.tailForm.valid) {
        this.loading = true;
        this.restApi.createTail(this.tail).subscribe((data: {}) => {
          this.loadTails();
          this.loading = false;
          this.tailFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateTail(id: any) {
    if ((this.tail.dtRangeStart) && (this.tail.dtRangeFinish) &&
      (this.tail.dtRangeStart >= this.tail.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.tailForm.form.markAllAsTouched();
      if (this.tailForm.valid) {
        this.loading = true;
        this.restApi.updateTail(id, this.tail).then(data => {
          this.loadTails();
          this.loading = false;
          this.tailFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteTail(id: any) {
    this.loading = true;
    this.tail.dtRangeFinish = new Date();
    this.restApi.updateTail(id, this.tail).then(data => {
      this.clearErrorMess();
      this.loadTails();
      this.loading = false;
      this.tailFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Regularities
  loadRegularities() {
    console.log('load delays');
    this.loading = true;
    return this.restApi.getRegularities().subscribe((data: ReferanceRegularitie[]) => {
      this.privateRegularities.length = 0;
      for (const item of data) {
        const regularitie = new ReferanceRegularitie();
        Object.assign(regularitie, item);
        this.privateRegularities.push(regularitie);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get regularities(): ReferanceRegularitie[] {
    return this.privateRegularities
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.nameEnglish.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1 ||
            item.nameLocal.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadRegularitie(id: number) {
    console.log('load delay ' + id);
    return this.restApi.getRegularitie(id).subscribe((data: ReferanceRegularitie) => {
      this.activeState = 'state-update';
      const regularitie = new ReferanceRegularitie();
      Object.assign(regularitie, data);
      this.regularitie = regularitie;
    }, err => this.displayError(err));
  }

  createRegularitie() {
    this.activeState = 'state-append';
    this.regularitie = new ReferanceRegularitie();
    this.regularitieForm.reset();
  }

  addRegularitie() {
    if ((this.regularitie.dtRangeStart) &&
        (this.regularitie.dtRangeFinish) &&
        (this.regularitie.dtRangeStart >= this.regularitie.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.regularitieForm.form.markAllAsTouched();
      if (this.regularitieForm.valid) {
        this.loading = true;
        this.restApi.createRegularitie(this.regularitie).subscribe((data: {}) => {
          this.loadRegularities();
          this.loading = false;
          this.regularitieFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateRegularitie(id: any) {
    if ((this.regularitie.dtRangeStart) && (this.regularitie.dtRangeFinish) &&
      (this.regularitie.dtRangeStart >= this.regularitie.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.regularitieForm.form.markAllAsTouched();
      if (this.regularitieForm.valid) {
        this.loading = true;
        this.restApi.updateRegularitie(id, this.regularitie).subscribe(data => {
          this.loadRegularities();
          this.loading = false;
          this.regularitieFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteRegularitie(id: any) {
    this.loading = true;
    this.regularitie.dtRangeFinish = new Date();
    this.restApi.updateRegularitie(id, this.regularitie).subscribe(data => {
      this.clearErrorMess();
      this.loadRegularities();
      this.loading = false;
      this.regularitieFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Stand
  loadStands() {
    console.log('load stands');
    this.loading = true;
    return this.restApi.getStands().subscribe((data: ReferanceStand[]) => {
      this.privateStands.length = 0;
      for (const item of data) {
        const stand = new ReferanceStand();
        Object.assign(stand, item);
        this.privateStands.push(stand);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get stands(): ReferanceStand[] {
    return this.privateStands
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.name.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadStand(id: number) {
    console.log('load stand ' + id);
    return this.restApi.getStand(id).subscribe((data: ReferanceStand) => {
      this.activeState = 'state-update';
      const stand = new ReferanceStand();
      Object.assign(stand, data);
      this.stand = stand;
    }, err => this.displayError(err));
  }

  createStand() {
    this.activeState = 'state-append';
    this.stand = new ReferanceStand();
    this.standForm.reset();
  }

  addStand() {
    if ((this.stand.dtRangeStart) && (this.stand.dtRangeFinish) && (this.stand.dtRangeStart >= this.stand.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.standForm.form.markAllAsTouched();
      if (this.standForm.valid) {
        this.loading = true;
        this.restApi.createStand(this.stand).subscribe((data: {}) => {
          this.loadStands();
          this.loading = false;
          this.standFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateStand(id: any) {
    if ((this.stand.dtRangeStart) && (this.stand.dtRangeFinish) &&
      (this.stand.dtRangeStart >= this.stand.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.standForm.form.markAllAsTouched();
      if (this.standForm.valid) {
        this.loading = true;
        this.restApi.updateStand(id, this.stand).subscribe(data => {
          this.loadStands();
          this.loading = false;
          this.standFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteStand(id: any) {
    this.loading = true;
    this.stand.dtRangeFinish = new Date();
    this.restApi.updateStand(id, this.stand).subscribe(data => {
      this.clearErrorMess();
      this.loadStands();
      this.loading = false;
      this.standFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Stand Type
  loadStandTypes() {
    console.log('load stand types');
    this.loading = true;
    return this.restApi.getStandTypes().subscribe((data: ReferanceStandType[]) => {
      this.privateStandTypes.length = 0;
      for (const item of data) {
        const standType = new ReferanceStandType();
        Object.assign(standType, item);
        // Вмесето this.privateStandTypes.push(standType) применен способ вставки
        // позволяющий компоненту ng-select увидеть измнения в массиве
        // раздел документации: Change Detection с официального сайта
        this.privateStandTypes = [...this.privateStandTypes, standType];
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  /**
   * Функция вывода списка типов стоянок для ng-select
   */
  get standTypesForList(): ReferanceStandType[] {
    return this.privateStandTypes;
  }

  get standTypes(): ReferanceStandType[] {
    return this.privateStandTypes
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.names.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadStandType(id: number) {
    console.log('load stand type ' + id);
    return this.restApi.getStandType(id).subscribe((data: ReferanceStandType) => {
      console.log(data);
      this.activeState = 'state-update';
      const standType = new ReferanceStandType();
      Object.assign(standType, data);
      this.standType = standType;
      console.log(this.standType);
    }, err => this.displayError(err));
  }

  createStandType() {
    this.activeState = 'state-append';
    this.standType = new ReferanceStandType();
    this.standTypeForm.reset();
  }

  addStandType() {
    if ((this.standType.dtRangeStart) && (this.standType.dtRangeFinish) && (this.standType.dtRangeStart >= this.standType.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.standTypeForm.form.markAllAsTouched();
      if (this.standTypeForm.valid) {
        this.loading = true;
        this.restApi.createStandType(this.standType).subscribe((data: {}) => {
          this.loadStandTypes();
          this.loading = false;
          this.standTypeFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateStandType(id: any) {
    if ((this.standType.dtRangeStart) && (this.standType.dtRangeFinish) &&
      (this.standType.dtRangeStart >= this.standType.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.standTypeForm.form.markAllAsTouched();
      if (this.standTypeForm.valid) {
        this.loading = true;
        this.restApi.updateStandType(id, this.standType).subscribe(data => {
          this.loadStandTypes();
          this.loading = false;
          this.standTypeFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteStandType(id: any) {
    this.loading = true;
    this.standType.dtRangeFinish = new Date();
    this.restApi.updateStandType(id, this.standType).subscribe(data => {
      this.clearErrorMess();
      this.loadStandTypes();
      this.loading = false;
      this.standTypeFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Terminals
  loadTerminals() {
    console.log('load terminals');
    this.loading = true;
    return this.restApi.getTerminals().subscribe((data: ReferanceTerminal[]) => {
      this.privateTerminals.length = 0;
      for (const item of data) {
        const terminal = new ReferanceTerminal();
        Object.assign(terminal, item);
        this.privateTerminals.push(terminal);
      }
      this.loading = false;
    }, err => this.displayError(err));
  }

  get terminals(): ReferanceTerminal[] {
    return this.privateTerminals
      .filter(item => {
        if (this.filterIata.length !== 0 || this.filterName.length !== 0) {
          // Если нет запомненой страницы, записываем текущую
          this.setSelectedFirst();
        } else {
          // Фильтры не используются
          // Если был переход с другой страницы, переход на нее обратно
          this.setSelectedPage();
        }
        return (this.filterName == '' || item.name.toLowerCase().indexOf(this.filterName.toLowerCase()) != -1);
      })
      .sort((a, b) => this.sortObject(a, b));
  }

  loadTerminal(id: number) {
    console.log('load terminal ' + id);
    return this.restApi.getTerminal(id).subscribe((data: ReferanceTerminal) => {
      console.log('load terminal');
      console.log(data);
      this.activeState = 'state-update';
      const terminal = new ReferanceTerminal();
      Object.assign(terminal, data);
      this.terminal = terminal;
      console.log(this.terminal);
    }, err => this.displayError(err));
  }

  createTerminal() {
    this.activeState = 'state-append';
    this.terminal = new ReferanceTerminal();
    this.terminalForm.reset();
  }

  addTerminal() {
    if ((this.terminal.dtRangeStart) && (this.terminal.dtRangeFinish) && (this.terminal.dtRangeStart >= this.terminal.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.terminalForm.form.markAllAsTouched();
      if (this.terminalForm.valid) {
        this.loading = true;
        this.restApi.createTerminal(this.terminal).subscribe((data: {}) => {
          this.loadTerminals();
          this.loading = false;
          this.terminalFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  updateTerminal(id: any) {
    if ((this.terminal.dtRangeStart) && (this.terminal.dtRangeFinish) &&
      (this.terminal.dtRangeStart >= this.terminal.dtRangeFinish)) {
      this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'finishGraterStart');
      this.errorType = 'error';
      this.userAction = 'error';
    } else {
      this.terminalForm.form.markAllAsTouched();
      if (this.terminalForm.valid) {
        this.loading = true;
        this.restApi.updateTerminal(id, this.terminal).subscribe(data => {
          this.loadTerminals();
          this.loading = false;
          this.terminalFormFade.nativeElement.click();
        }, err => this.displayError(err));
      }
    }
  }

  deleteTerminal(id: any) {
    this.loading = true;
    this.terminal.dtRangeFinish = new Date();
    this.restApi.updateTerminal(id, this.terminal).subscribe(data => {
      this.clearErrorMess();
      this.loadTerminals();
      this.loading = false;
      this.terminalFormFade.nativeElement.click();
    }, err => this.displayError(err));
  }

  // Создание копии, аргумент - копируемый объект
  duplicateReference(ref: any) {
    this.activeState = 'state-append';
    ref.id = undefined;
    ref.lastupdate = undefined;
    console.log(ref);
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  loadTab() {
    switch (this.activeTab) {
      case 'tab-airports': {
        this.loadCities();
        this.loadAirports();
        break;
      }
      case 'tab-airlines': {
        this.loadCountries();
        this.loadAirlines();
        break;
      }
      case 'tab-aircraft-types': {
        this.loadAircraftClasses();
        this.loadAircraftKinds();
        this.loadAircraftTypes();
        break;
      }
      case 'tab-delays': {
        this.loadDelays();
        break;
      }
      case 'tab-delay-sources': {
        this.loadDelaySources();
        break;
      }
      case 'tab-movements': {
        this.loadRegularities();
        this.loadMovements();
        break;
      }
      case 'tab-seasons': {
        this.loadSeasons();
        break;
      }
      case 'tab-regions': {
        this.loadRegions();
        break;
      }
      case 'tab-countries': {
        this.loadRegions();
        this.loadCountries();
        break;
      }
      case 'tab-cities': {
        this.loadCountries();
        this.loadCities();
        break;
      }
      case 'tab-aircraft-kinds': {
        this.loadAircraftKinds();
        break;
      }
      case 'tab-aircraft-classes': {
        this.loadAircraftClasses();
        break;
      }
      case 'tab-tails': {
        this.loadAircraftTypes();
        this.loadTails();
        break;
      }
      case 'tab-regularities': {
        this.loadRegularities();
        break;
      }
      case 'tab-stands': {
        this.loadStandTypes();
        this.loadStands();
        break;
      }
      case 'tab-stand-types': {
        this.loadStandTypes();
        break;
      }
      case 'tab-terminals': {
        this.loadTerminals();
        break;
      }
    }
  }

  changeTab(event: { target: { id: string; }; }) {
    this.activeTab = event.target.id;
    this.order = new ReferanceOrder();
    this.filterIata = this.filterIcao = this.filterName = this.filterType = '';
    this.currentPage = 1;
    this.errorMessage = '';
    this.loadTab();
  }

  showTab(item: string): boolean {
    return item === this.activeTab;
  }

  showButton(item: string): boolean {
    return item === this.activeState;
  }

  clearErrorMess() {
    this.errorMessage = '';
    this.userAction = '';
    this.errorType = '';
    this.executableFunction = null;
  }

  closeWithoutSaving() {
    switch (this.activeTab) {
      case 'tab-airports':
        this.airportFormFade.nativeElement.click();
        break;
      case 'tab-airlines':
        this.airlineFormFade.nativeElement.click();
        break;
      case 'tab-aircraft-types':
        this.aircraftTypeFormFade.nativeElement.click();
        break;
      case 'tab-movements':
        this.movementFormFade.nativeElement.click();
        break;
      case 'tab-delays':
        this.delayFormFade.nativeElement.click();
        break;
      case 'tab-delay-sources':
        this.delaySourceFormFade.nativeElement.click();
        break;
      case 'tab-seasons':
        this.seasonFormFade.nativeElement.click();
        break;
      case 'tab-regions':
        this.regionFormFade.nativeElement.click();
        break;
      case 'tab-countries':
        this.countryFormFade.nativeElement.click();
        break;
      case 'tab-cities':
        this.cityFormFade.nativeElement.click();
        break;
      case 'tab-aircraft-kinds':
        this.aircraftKindFormFade.nativeElement.click();
        break;
      case 'tab-aircraft-classes':
        this.aircraftClassFormFade.nativeElement.click();
        break;
      case 'tab-tails':
        this.tailFormFade.nativeElement.click();
        break;
      case 'tab-regularities':
        this.regularitieFormFade.nativeElement.click();
        break;
      case 'tab-stands':
        this.standFormFade.nativeElement.click();
        break;
      case 'tab-stand-types':
        this.standTypeFormFade.nativeElement.click();
        break;
      case 'tab-terminals':
        this.terminalFormFade.nativeElement.click();
        break;
      default:
        break;
    }
    this.errorMessage = '';
  }

  /**
   * Функция обработки изменения текущей сортировки столбца
   *
   * @param element Текущий столбец по которому нажал пользователь
   * @param parent Блок заголовков со всеми столбцами таблицы
   */
  sortBy(element, parent) {
    const order = element.getAttribute('data-method');
    // Сохранение имени поля из столбца, по которому применять фильтр
    this.order.orderField = element.getAttribute('field');
    parent.querySelectorAll('th').forEach(n => n.classList.remove('asc'));
    parent.querySelectorAll('th').forEach(n => n.classList.remove('desc'));
    parent.querySelectorAll('th').forEach(n => n.setAttribute('data-method', ''));
    if (order == '') {
      // По возрастанию
      element.setAttribute('data-method', 'asc');
      element.classList.add('asc');
      this.order.orderBy = 'asc';
    } else if (order == 'asc') {
      // По убыванию
      element.setAttribute('data-method', 'desc');
      element.classList.add('desc');
      this.order.orderBy = 'desc';
    } else if (order == 'desc') {
      // Без сортировки
      // Отчистка имени поля по которому применять фильтр, показ данных как были полученны
      this.order.orderField = '';
      this.order.orderBy = '';
    }
  }


  sortObject (a, b) {
    //console.log('orderBy', this.order.orderBy)
    if (this.order.orderBy == 'asc') {
      if (typeof a[this.order.orderField] == "number")
        return a[this.order.orderField] > b[this.order.orderField] ? 1 : -1;
      else if (typeof a[this.order.orderField] == "string")
        return a[this.order.orderField].toLowerCase() > b[this.order.orderField].toLowerCase() ? 1 : -1;
      else
        return a[this.order.orderField].toString().toLowerCase() > b[this.order.orderField].toString().toLowerCase() ? 1 : -1;
    } else if (this.order.orderBy == 'desc') {
      if (typeof a[this.order.orderField] == "number")
        return a[this.order.orderField] < b[this.order.orderField] ? 1 : -1;
      else if (typeof a[this.order.orderField] == "string")
        return a[this.order.orderField].toLowerCase() < b[this.order.orderField].toLowerCase() ? 1 : -1;
      else
        return a[this.order.orderField].toString().toLowerCase() < b[this.order.orderField].toString().toLowerCase() ? 1 : -1;
    }
  }

  downloadFile(name: string, type: string) {
    const link = name + '?export=' + type;
    return this.restApi.downloadFile(link).subscribe(data => {
      const blob = new Blob([data], {type: 'application'});
      this.fileSaverService.save(blob, name + '.' + type);
    });
  }

  getFieldsFromDB(referanceName: string) {
    this.importInit();
    this.import = new ReferanceImport();
    this.import.activeReferanceName = referanceName;
    return this.restApi.getFieldsFromBD(referanceName).subscribe((data) => {
      const fields = [];
      // Формирование списка полей доступных для импорта, в массив записывается
      // имя поля и признак обязательности (true обязательное, иначе false)
      // TODO пока все поля являются ябязательными, нет функционала на бэке
      for (const item of data) {
        fields.push([item, true]);
      }
      this.import.fieldsImpotrtFromDB = fields;
    }, err => {
      if (err.type) {
        this.errorType = err.type;
        this.errorMessage = err.message;
        console.log('Error: ' + err.message + '\ndetail:' + err.detail);
      } else {
        this.errorMessage = err;
      }
    });
  }

  importInit() {
    this.importStep = 1;
    this.importLoadAnimation = false;
    this.importBindingCheck = false;
  }

  incrementImportStep() {
    // Если текущий шаг равен "привязка данных", проверяется
    // привязка обязательных полей импорта к данным файла,
    // если не все все поля привязанны, остается текущий шаг импорта
    if (this.importStep === 3) {
      this.importBindingCheck = true;
      if (this.import.unmapedFields().length != 0) {
        return;
      }
    }

    this.importStep += 1;

    // Если текущий шаг равен "привязка данных"
    // вызывается функция автоматического связывания полей
    // по имени
    if (this.importStep === 3) {
      this.import.automaticBindingImportedFieldsByName();
    }

    // Если текущий шаг импорта стал равен "Валидация данных"
    // необходимо отправить массив на проверку в бэк
    if (this.importStep === 4) {
      this.import.makeImportingHeaderFile();
      this.import.makeImportingHeaderDb();
      this.import.makeDataImporting();
      this.importDataValidation();
    }
  }

  decrementImportStep() {
    this.importStep -= 1;
  }

  onChangeImportConfig() {
    this.importLoadAnimation = true;
    setTimeout(() => {
                 this.parseImportFile();
                 this.importLoadAnimation = false;
               },
               500);
  }

  onChangeFileImport(files: File[]) {
    this.import.clear();
    if (files[0]) {
      this.importLoadAnimation = true;
      const reader = new FileReader();
      reader.readAsText(files[0]);

      reader.onload = () => {
        this.import.originalText = reader.result;
        this.parseImportFile();
        this.incrementImportStep();
      };

      reader.onerror = () => {
        this.errorType = 'error';
        this.errorMessage = 'Error is occured while reading file';
      };
      this.importLoadAnimation = false;
    }
  }

  parseImportFile() {
    Papa.parse<string>(this.import.originalText as string, {
      header: this.importHeaderParse,
      skipEmptyLines: true,
      delimiter: this.importDelimiter,
      newline: this.importNewLine,
      complete: (result) => {
        this.import.fieldsImpotrtFromFile = result.meta.fields;
        this.import.dataImport = result.data;
        this.import.makeFileHeader();
        this.import.makeDataFromFile();
      }
    });
  }

  importDataValidation() {
    console.log('importDataValidation');
    console.log(JSON.stringify(this.import.dataImportingJSON));
    this.importLoadAnimation = true;
    this.restApi.importDataValidation(this.import.dataImportingJSON,
                                      this.import.activeReferanceName)
      .subscribe((data: any[]) => {
        this.import.dataValidationResult = data;
        this.import.dataImportingValidationResultCalculate();
        this.importLoadAnimation = false;
      }, err => {
        console.log(err);
        if (err) {
          this.import.dataValidationResult = err;
          this.import.dataImportingValidationResultCalculate();
          this.importLoadAnimation = false;
        }
      });
  }

  editImportDataRow(index: number) {
    this.importDataEditRowIndex = index;
  }

  saveEditImportDataField(column: string, value: string) {
    this.import.updateImportDataFieldById(this.importDataEditRowIndex, column, value);
  }

  saveEditImportDataRow() {
    this.importDataEditRowIndex = -1;
    this.import.makeDataImporting();
    this.importDataValidation();
  }

  importShowNextError() {
    this.importPopoverClickRow = -1;
    if (this.import.dataImport.length - 1 === this.importCurrentShowBadRow) {
      this.importCurrentShowBadRow = -1;
      this.importLastShowBadRow = -1;
    }
    for (let i = this.importLastShowBadRow + 1; i < this.import.dataImport.length; i++) {
      if ((i === this.import.dataImport.length - 1) &&
        this.import.dataValidationResult[i]['type'].toLowerCase() != 'error') {
        this.importCurrentShowBadRow = 0;
        this.importLastShowBadRow = 0;
      } else {
        this.importCurrentShowBadRow = i;
        this.importLastShowBadRow = i;
      }

      if (this.import.dataValidationResult[i]['type'].toLowerCase() === 'error') {
        this.importDataPage = Math.ceil((i + 1) / this.importItemsPerPage);
        break;
      }
    }
  }

  isShowPopover(popover, index: number) {
    if (this.importPopoverClickRow === index) {
      popover.open();
    } else if (this.importCurrentShowBadRow === index && this.importDataEditRowIndex === -1) {
      popover.open();
    } else {
      popover.close();
    }
  }

  clickShowPopover(index: number) {
    if (this.importCurrentShowBadRow === index) {
      this.importPopoverClickRow = index;
    }
    this.importCurrentShowBadRow = -1;
    if (this.importPopoverClickRow === index) {
      this.importPopoverClickRow = -1;
    } else {
      this.importPopoverClickRow = index;
    }
  }

  startImport() {
    this.importLoadAnimation = true;
    this.restApi.startImport(this.import.dataImportingJSON,
                             this.import.activeReferanceName)
      .subscribe((data: any[]) => {
        this.import.dataImportResult = data;
        this.import.dataImportResultCalculate();
        this.importLoadAnimation = false;
        this.refreshDataAfterImport();
      }, err => {
        if (err) {
          this.import.dataImportResult = err;
          this.import.dataImportResultCalculate();
          this.importLoadAnimation = false;
          this.refreshDataAfterImport();
        }
      });

    this.incrementImportStep();
  }

  refreshDataAfterImport() {
    switch (this.import.activeReferanceName) {
      case 'tails':
        this.loadTails();
        break;
      case 'aircraft_types':
        this.loadAircraftTypes();
        break;
      case 'aircraft_kinds':
        this.loadAircraftKinds();
        break;
      case 'aircraft_classes':
        this.loadAircraftClasses();
        break;
      case 'cities':
        this.loadCities();
        break;
      case 'countries':
        this.loadCountries();
        break;
      case 'regions':
        this.loadRegions();
        break;
      case 'regularities':
        this.loadRegularities();
        break;
      case 'stands':
        this.loadStands();
        break;
      case 'standTypes':
        this.loadStandTypes();
        break;
      case 'terminals':
        this.loadTerminals();
        break;
      case 'delay_sources':
        this.loadDelaySources();
        break;
      case 'airports':
        this.loadAirports();
        break;
      case 'airlines':
        this.loadAirlines();
        break;
      case 'movement_types':
        this.loadMovements();
        break;
      case 'delays':
        this.loadDelays();
        break;
      case 'seasons':
        this.loadSeasons();
        break;
    }
  }

  saveToFileImportResut() {
    const TEXT = new Blob([this.import.dataImportResultErrorMessage()], {type: 'application/text'});
    this.fileSaverService.save(TEXT, this.import.activeReferanceName + '_err_log.txt');
  }

  absoluteIndex(indexOnPage: number): number {
    return this.importItemsPerPage * (this.importDataPage - 1) + indexOnPage;
  }

  setSelectedFirst() {
    if (this.importDataPagePrevious === 0) {
      this.importDataPagePrevious = this.currentPage;
      // Установка вывода с первой для показа результатов фильтра
      this.currentPage = 1;
    }
  }

  setSelectedPage() {
    if (this.importDataPagePrevious !== 0) {
      this.currentPage = this.importDataPagePrevious;
      this.importDataPagePrevious = 0;
    }
  }

  displayError(err) {
    this.loading = false;
    if (err.type) {
      this.errorType = err.type;
      this.errorMessage = err.message;
      console.log('Error: ' + err.message + '\ndetail:' + err.detail);
    } else {
      this.errorMessage = err;
    }
  }

  confirmAction(func: Function) {
    this.userAction = 'deleteItem';
    this.errorType = 'warning';
    this.errorMessage = this.globalI18n.getMessage(Module.MasterData, 'deleteItem');
    this.executableFunction = func;
  }

}
