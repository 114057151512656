<nz-layout class="h-100">
	<nz-header class="bg-white border-bottom">
		<nz-space>
			<p
				*nzSpaceItem
				class="text-black-50"
				i18n="@@wb-mobile.header.name">
				Weight balance mobile
			</p>
		</nz-space>
	</nz-header>
	<nz-content class="h-100 bg-white">
		<aac-weight-balance-mobile-list
			*ngIf="currentViewState === ViewState.SHOW_LIST; else itemView"
			(selectedFlight)="selectedFlight($event)"
			[flights]="flights"></aac-weight-balance-mobile-list>
		<ng-template #itemView>
			<aac-weight-balance-mobile-item
				[flight]="currentFlight"
				[document]="selectDocument"
				(documentChange)="documentChange($event)"></aac-weight-balance-mobile-item>
		</ng-template>

		<nz-modal
			[(nzVisible)]="showConfirmActionDialog"
			nzTitle="Confirm action"
			nzClosable="false"
			i18n-nzTitle="@@wb-mobile.confirmDialog.name">
			<ng-container *nzModalContent>
				<p class="mb-1">
					<span i18n="@@wb-mobile.confirmDialog.field.code.enterCode">Enter the code</span>
					<span class="font-weight-bold"> {{ codeForConfirmDialog }} </span>
					<span i18n="@@wb-mobile.confirmDialog.field.code.toConfirmAction">to confirm the action</span>
				</p>
				<input
					nz-input
					placeholder="Enter code"
					type="number"
					[(ngModel)]="userEnteredCode"
					(keyup.enter)="confirmActionDialogOk()"
					nzStatus="{{ checkCodeStatusForConfirmDialog }}"
					i18n-placeholder="@@wb-mobile.confirmDialog.field.code.placeholder" />
				<p
					class="mt-2 mb-1"
					i18n="@@wb-mobile.confirmDialog.field.note.text">
					Note
				</p>
				<textarea
					nz-input
					nzAutosize
					[(ngModel)]="confirmDialogNote"></textarea>
			</ng-container>
			<div *nzModalFooter>
				<button
					nz-button
					nzType="default"
					(click)="confirmActionDialogCancel()"
					i18n="@@wb-mobile.confirmDialog.button.cancel">
					Cancel
				</button>
				<button
					nz-button
					nzType="primary"
					(click)="confirmActionDialogOk()">
					<span
						*ngIf="confirmDialogType === DialogAction.APPROVED"
						i18n="@@wb-mobile.confirmDialog.button.approve"
						>Approve</span
					>
					<span
						*ngIf="confirmDialogType === DialogAction.REJECT"
						i18n="@@wb-mobile.confirmDialog.button.reject"
						>Reject</span
					>
				</button>
			</div>
		</nz-modal>
	</nz-content>
	<nz-footer>
		<div
			*ngIf="currentViewState === ViewState.SHOW_ITEM"
			nz-flex
			[nzVertical]="false"
			[nzJustify]="'space-between'">
			<div class="flex-item even">
				<button
					nz-button
					[nzSize]="'large'"
					(click)="backToFlightList()">
					<i class="material-icons">keyboard_arrow_left</i>
				</button>
			</div>
			<div class="flex-item even">
				<button
					nz-button
					[nzSize]="'large'"
					(click)="rejectFlightDocuments()">
					<i class="material-icons">close</i>
				</button>
			</div>
			<div class="flex-item even">
				<button
					nz-button
					[nzSize]="'large'"
					(click)="acceptFlightDocuments()">
					<i class="material-icons">check</i>
				</button>
			</div>
		</div>
	</nz-footer>
</nz-layout>
