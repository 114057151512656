<nz-empty
	*ngIf="flights.length === 0; else flightList"
	nzNotFoundImage="simple"></nz-empty>
<ng-template #flightList>
	<nz-card
		*ngFor="let flight of flights"
		class="mb-2 p-2 bg-light"
		(click)="selectFlight(flight.id)">
		<div
			nz-flex
			[nzVertical]="false"
			[nzJustify]="'space-between'">
			<div class="flex-item">
				<span
					nz-typography
					class="text-uppercase"
					i18n="@@wb-mobile.flights.item.flight"
					>Flight</span
				>
				<p class="h2">
					{{ flight.airlineIata }}<span *ngIf="flight.airlineIata !== ''">-</span>{{ flight.flightNumber }}
				</p>
			</div>
			<div class="flex-item">
				<span
					nz-typography
					class="text-uppercase"
					i18n="@@wb-mobile.flights.item.acreg"
					>A\C Reg</span
				>
				<p class="h2">{{ flight.tail }}</p>
			</div>
		</div>

		<div
			nz-flex
			[nzVertical]="false"
			[nzJustify]="'space-between'">
			<div class="flex-item">
				<span
					nz-typography
					class="text-uppercase"
					i18n="@@wb-mobile.flights.item.datePlan"
					>Date plan</span
				>
				<p class="h5">{{ flight.route[0].dtDeparture[0] | dateFormat : 'DD.MM.YY HH:mm' : userShowTime }}</p>
			</div>
			<div class="flex-item">
				<span
					nz-typography
					class="text-uppercase"
					i18n="@@wb-mobile.flights.item.route"
					>Route</span
				>
				<p class="h5">{{ flight.route[0].airportIata }}-{{ flight.route[1].airportIata }}</p>
			</div>
			<div class="flex-item">
				<span
					nz-typography
					class="text-uppercase"
					i18n="@@wb-mobile.flights.item.documents"
					>Documents</span
				>
				<p
					*ngIf="flight.edno !== 0 && flight.electronicDocument !== null"
					class="h5">
					{{ flight.edno }}
				</p>
			</div>
		</div>

		<div *ngIf="flight.electronicDocument === null">
			<div
				nz-flex
				[nzVertical]="false"
				[nzJustify]="'center'">
				<div class="w-100 mt-3 text-center rounded-pill border border-secondary bg-light text-muted text-uppercase">
					<span i18n="@@wb-mobile.flights.state.noDocumentsToSign">No documents to sign</span>
				</div>
			</div>
		</div>

		<div
			*ngIf="flight.electronicDocument?.status === documentStatus.SENT"
			nz-flex
			[nzVertical]="false"
			[nzJustify]="'center'">
			<div class="w-100 mt-3 text-center rounded-pill border border-secondary bg-primary text-white text-uppercase">
				{{ getStatusName(flight.electronicDocument?.status) }}
			</div>
		</div>
		<div
			*ngIf="flight.electronicDocument?.status === documentStatus.APPROVED"
			nz-flex
			[nzVertical]="false"
			[nzJustify]="'center'">
			<div class="w-100 mt-3 text-center rounded-pill border border-secondary bg-success text-white text-uppercase">
				<span i18n="@@wb-mobile.flights.state.approved">Approved</span>
			</div>
		</div>
		<div
			*ngIf="flight.electronicDocument?.status === documentStatus.REJECTED"
			nz-flex
			[nzVertical]="false"
			[nzJustify]="'center'">
			<div class="w-100 mt-3 text-center rounded-pill border border-secondary bg-danger text-white text-uppercase">
				<span i18n="@@wb-mobile.flights.state.rejected">Rejected</span>
			</div>
		</div>
	</nz-card>
</ng-template>
