export class Document {
	preparedAt: string;
	preparedBy: string;
	text: string;
}

export class UpdateDocumentDto {
	flight_id: number;
	edition_number: number;
	pilot: string;
	pilot_decision_date: Date;
	status: string;
	note: string;
}
