import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { Module } from '@shared/models/module-name';
import { MasterDataService } from '@shared/data-provider/master-data-api/api/masterData.service';
import { convertDateToUTC } from '@shared/functions/dateFunctions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MasterDataFunctionsService } from '../../services/master-data.service';
import { CustomFormValidators } from '@shared/custom-form-validators';
import { Region } from '@shared/data-provider/master-data-api/model/region';
import { MasterDataStore } from '../../shared/master-data-store';
import { Directory } from '../../models/directory';
import { SettingsService } from '@core/services/settings/settings.service';

interface ExtendedRegion extends Region {
	nameEn?: string;
	nameRu?: string;
	nameLocal?: string;
	dtRangeStart?: Date;
	dtRangeFinish?: Date;
}

@Component({
	selector: 'app-regions',
	templateUrl: './regions.component.html',
	styleUrls: ['./regions.component.less'],
})
export class RegionsComponent extends MasterDataStore implements OnInit {
	constructor(
		private apiMasterData: MasterDataService,
		private settingsService: SettingsService,
		private modal: NzModalService,
		private fb: UntypedFormBuilder,
		private globalI18n: GlobalI18n,
		private functionsService: MasterDataFunctionsService
	) {
		super(Directory.Regions);
	}

	private formValidators: CustomFormValidators = new CustomFormValidators();

	private _list: ExtendedRegion[] = [];
	currentItem: ExtendedRegion;
	form: FormGroup;

	settingValue = {
		loadingList: false,
		loadingModal: false,
		saving: false,
		searchIata: '',
		searchIcao: '',
		searchName: '',
		isVisibleModal: false,
		loadedPercent: null,
		pageIndex: 1,
	};

	listOfColumn = [
		{
			title: 'IATA',
			compare: (a: ExtendedRegion, b: ExtendedRegion) => a.iata.localeCompare(b.iata),
			priority: false,
		},
		{
			title: 'ICAO',
			compare: (a: ExtendedRegion, b: ExtendedRegion) => a.icao.localeCompare(b.icao),
			priority: false,
		},
		{
			title: 'Name',
			compare: (a: ExtendedRegion, b: ExtendedRegion) => a.nameLocal.localeCompare(b.nameLocal),
			priority: false,
		},
		{
			title: 'Last Update',
			compare: (a: ExtendedRegion, b: ExtendedRegion) => Date.parse(a.lastupdate) - Date.parse(b.lastupdate),
			priority: false,
		},
	];

	ngOnInit() {
		this.itemList$.subscribe((items: Region[]) => {
			this._list = items.map(item => {
				return {
					...item,
					nameEn: item.name[0],
					nameRu: item.name[1],
					nameLocal: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
					dtRangeStart: new Date(item.dtRange[0]),
					dtRangeFinish: new Date(item.dtRange[1]),
				};
			});
			this.settingValue.loadingList = false;
		});
		// this.loadList();

		this.item$.subscribe((item: Region) => {
			const modifyItem = {
				...item,
				nameEn: item.name[0],
				nameRu: item.name[1],
				nameLocal: this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
				dtRangeStart: new Date(item.dtRange[0]),
				dtRangeFinish: new Date(item.dtRange[1]),
			};
			this.currentItem = modifyItem;
			this.form = this.createForm(modifyItem);
			this.showModal();
			this.settingValue.loadingList = false;
		});

		this.error$.subscribe(err => {
			console.error(err);
			this.settingValue.saving = false;
			this.displayError(err);
		});

		this.commandSuccess$.subscribe(() => {
			this.settingValue.saving = false;
			this.closeModal();
		});

		// this.item$
		//   .pipe(
		//     map((item: Region) => {
		//       console.log('map load ok', item);
		//       return {
		//         ...item,
		//         nameEn: item.name[0],
		//         nameRu: item.name[1],
		//         nameLocal:
		//           this.settingsService.language == 'EN'
		//             ? item.name[0]
		//             : item.name[1],
		//         dtRangeStart: new Date(item.dtRange[0]),
		//         dtRangeFinish: new Date(item.dtRange[1]),
		//       };
		//     })
		//   )
		//   .subscribe((data: ExtendedRegion) => {
		//     console.log('item load ok', data);
		//     this.currentItem = data;
		//     this.form = this.createForm(data);
		//     this.showModal();
		//     this.settingValue.loadingList = false;
		//   });

		this.listOfColumn = [
			{
				title: 'IATA',
				compare: (a: ExtendedRegion, b: ExtendedRegion) => a.iata.localeCompare(b.iata),
				priority: false,
			},
			{
				title: 'ICAO',
				compare: (a: ExtendedRegion, b: ExtendedRegion) => a.icao.localeCompare(b.icao),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnName'),
				compare: (a: ExtendedRegion, b: ExtendedRegion) => a.nameLocal.localeCompare(b.nameLocal),
				priority: false,
			},
			{
				title: this.globalI18n.getMessage(Module.MasterData, 'columnLastUpdate'),
				compare: (a: ExtendedRegion, b: ExtendedRegion) => Date.parse(a.lastupdate) - Date.parse(b.lastupdate),
				priority: false,
			},
		];
	}

	loadList() {
		// this.settingValue.loadingList = true;
		// this.apiMasterData.selectRecords('regions').subscribe((data: Region[]) => {
		//   this._list = data.map(item => {
		//     return {
		//       ...item,
		//       nameEn: item.name[0],
		//       nameRu: item.name[1],
		//       nameLocal:
		//         this.settingsService.language == 'EN' ? item.name[0] : item.name[1],
		//       dtRangeStart: new Date(item.dtRange[0]),
		//       dtRangeFinish: new Date(item.dtRange[1]),
		//     };
		//   });
		//   this.settingValue.loadingList = false;
		// });
	}

	get listOfDisplay(): ExtendedRegion[] {
		// При установленных фильтрах необходимо сбросить пагинацию на 1 страницу
		if (
			this.settingValue.searchIata.length !== 0 ||
			this.settingValue.searchIcao.length !== 0 ||
			this.settingValue.searchName.length !== 0
		) {
			this.onPageIndexChanged(1);
		}
		return this._list.filter(item => {
			return (
				item.iata.toLowerCase().indexOf(this.settingValue.searchIata.toLowerCase()) != -1 &&
				item.icao.toLowerCase().indexOf(this.settingValue.searchIcao.toLowerCase()) != -1 &&
				(item.nameRu.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1 ||
					item.nameEn.toLowerCase().indexOf(this.settingValue.searchName.toLowerCase()) != -1)
			);
		});
	}

	openItem(id: number) {
		this.readItem(id.toString());
		// this.settingValue.loadingList = true;
		// return this.apiMasterData
		//   .selectRecord('regions', id.toString())
		//   .pipe(
		//     map((item: Region) => {
		//       return {
		//         ...item,
		//         nameEn: item.name[0],
		//         nameRu: item.name[1],
		//         nameLocal:
		//           this.settingsService.language == 'EN'
		//             ? item.name[0]
		//             : item.name[1],
		//         dtRangeStart: new Date(item.dtRange[0]),
		//         dtRangeFinish: new Date(item.dtRange[1]),
		//       };
		//     })
		//   )
		//   .subscribe((data: ExtendedRegion) => {
		//     this.currentItem = data;
		//     this.form = this.createForm(data);
		//     this.showModal();
		//     this.settingValue.loadingList = false;
		//   });
	}

	addNew() {
		this.newItem();
		this.currentItem = {};
		this.form = this.createForm();
		this.showModal();
	}

	createForm(data?: ExtendedRegion) {
		return this.fb.group({
			iata: [
				data ? data.iata : '',
				[Validators.required, this.formValidators.onlyEnglishAndNumbers(), this.formValidators.lengthValidator(3)],
			],
			icao: [
				data ? data.icao : '',
				[Validators.required, this.formValidators.onlyEnglishAndNumbers(), this.formValidators.lengthValidator(4)],
			],
			nameEn: [data ? data.nameEn : '', [Validators.required, this.formValidators.onlyEnglish()]],
			nameRu: [data ? data.nameRu : '', [Validators.required]],
			dtRangeStart: [data ? convertDateToUTC(new Date(data.dtRangeStart)) : new Date(), [Validators.required]],
			dtRangeFinish: [
				data ? convertDateToUTC(new Date(data.dtRangeFinish)) : new Date('2099-12-31 23:59'),
				[Validators.required],
			],
		});
	}

	add() {
		this.form.markAllAsTouched();
		if (this.form.valid) {
			this.settingValue.saving = true;
			this.saveItem(this.createFromForm());
			// this.apiMasterData
			//   .insertRecord('regions', null, this.createFromForm())
			//   .subscribe({
			//     next: () => {
			//       this.closeModal();
			//       this.loadList();
			//     },
			//     error: err => {
			//       this.displayError(err);
			//     },
			//     complete: () => {
			//       this.settingValue.saving = false;
			//     },
			//   });
		}
	}

	save() {
		this.form.markAllAsTouched();
		if (this.form.valid) {
			this.settingValue.saving = true;
			this.updateItem(this.currentItem.id.toString(), this.createFromForm());
			// this.apiMasterData
			//   .updateRecord(
			//     'regions',
			//     this.currentItem.id.toString(),
			//     this.createFromForm()
			//   )
			//   .subscribe({
			//     next: () => {
			//       this.closeModal();
			//       this.loadList();
			//     },
			//     error: err => this.displayError(err),
			//     complete: () => {
			//       this.settingValue.saving = false;
			//     },
			//   });
		}
	}

	delete() {
		this.settingValue.saving = true;
		this.form.get('dtRangeFinish').setValue(new Date());
		this.deleteItem(this.currentItem.id.toString(), this.createFromForm());

		// this.apiMasterData
		//   .updateRecord(
		//     'regions',
		//     this.currentItem.id.toString(),
		//     this.createFromForm()
		//   )
		//   .subscribe({
		//     next: () => {
		//       this.closeModal();
		//       this.loadList();
		//     },
		//     error: err => this.displayError(err),
		//     complete: () => {
		//       this.settingValue.saving = false;
		//     },
		//   });
	}

	duplicate() {
		this.currentItem.id = undefined;
		this.currentItem.lastupdate = undefined;
	}

	showDeleteConfirm(): void {
		this.modal.confirm({
			nzTitle: this.globalI18n.getMessage(Module.MasterData, 'deleteItem'),
			nzOkText: this.globalI18n.getMessage(Module.MasterData, 'yes'),
			nzOkType: 'primary',
			nzOkDanger: true,
			nzOnOk: () => this.delete(),
			nzCancelText: this.globalI18n.getMessage(Module.MasterData, 'no'),
			nzOnCancel: () => console.log('Cancel'),
		});
	}

	createFromForm(): Region {
		return {
			...this.currentItem,
			...this.form.value,
			name: [this.form.get('nameEn').value, this.form.get('nameRu').value],
			dtRange: [this.form.get('dtRangeStart').value, this.form.get('dtRangeFinish').value],
		};
	}

	downloadCsvFile() {
		this.functionsService.downloadCsvFile('regions', this.settingValue.loadingList);
	}

	downloadXmlFile() {
		this.functionsService.downloadXmlFile('regions', this.settingValue.loadingList);
	}

	onPageIndexChanged(index: number) {
		this.settingValue.pageIndex = index;
	}

	showModal(): void {
		this.settingValue.isVisibleModal = true;
	}

	closeModal(): void {
		this.settingValue.isVisibleModal = false;
	}

	displayError(err): void {
		this.modal.create({
			nzTitle: 'Error',
			nzContent: err.message,
			nzClosable: false,
		});
	}
}
