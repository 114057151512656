import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
	RouteNetwork,
	RouteNetworkAddress,
	ACTIONS,
	ARRDEP,
	RouteNetworkDocMes,
	RouteNetworkItem,
} from '../../types/referance-route-network';
import { RouteNetworkService } from '../../services/route-network.service';
import { Module } from "@shared/models/module-name";
import { ReferanceAirport } from 'src/app/referance-module-data/referance-airport';
import { ReferanceAirline } from 'src/app/referance-module-data/referance-airline';
import { checkValidAddress } from '@shared/functions/validationAddresses';
import { NgForm }
from '@angular/forms';
import { GlobalI18n } from '@settings/global-i18n';
import { CHANNELS } from '@shared/const';

@Component({
	selector: 'route-network',
	templateUrl: './route-network.component.html',
	styleUrls: ['./route-network.component.less'],
})
export class RouteNetworkComponent implements OnInit {
	@Input() airports: ReferanceAirport[];
	@Input() airlines: ReferanceAirline[];
	@ViewChild('routeNetworkForm') routeNetworkForm: NgForm;
	@ViewChild('closeModal') closeModal: any;

	CHANNELS = CHANNELS;
	ACTIONS = ACTIONS;
	ARRDEP = ARRDEP;

	private _ROUTE_NETWORKS: Array<RouteNetwork> = [];
	// routeNetworks: Array<RouteNetwork> = [];
	routeNetwork: RouteNetwork = new RouteNetwork();
	userAnswer = null;

	message = {
		errorMessage: '',
		errorType: '',
		modalType: '',
	};

	view = {
		userAction: ACTIONS.ADD,
		loading: false,
		searchAirport: '',
		searchAirline: '',
	};

	constructor(private restApi: RouteNetworkService, public globalI18n: GlobalI18n) {}

	ngOnInit(): void {
		this.loadRouteNetworks();
	}

	createRouteNetwork() {
		this.routeNetwork = new RouteNetwork();
		this.view.userAction = ACTIONS.ADD;
	}

	loadRouteNetworks() {
		this.restApi.getRouteNetworks().then((data: RouteNetwork[]) => {
			this._ROUTE_NETWORKS = data;
		});
	}

	get routeNetworks(): Array<RouteNetwork> {
		return this._ROUTE_NETWORKS.filter(item => {
			if (this.view.searchAirline !== '' || this.view.searchAirport !== '') {
				return (
					item.airline.toUpperCase().includes(this.view.searchAirline.toUpperCase()) &&
					item.airport.toUpperCase().includes(this.view.searchAirport.toUpperCase())
				);
			} else {
				return item;
			}
		});
	}

	loadRouteNetwork(id: number) {
		this.restApi.getRouteNetwork(id).then((data: RouteNetwork) => {
			this.routeNetwork = new RouteNetwork(data);
			this.view.userAction = ACTIONS.EDIT;
		});
	}

	addRouteNetwork() {
		this.restApi.addRouteNetworks(this.routeNetwork).then(data => {
			this.closeModal.nativeElement.click();
			this.loadRouteNetworks();
		});
	}

	saveRouteNetwork(id: number) {
		this.routeNetworkForm.form.markAllAsTouched();
		if (this.routeNetworkForm.valid) {
			this.view.loading = true;
			this.restApi.saveRouteNetworks(id, this.routeNetwork).then(data => {
				this.view.loading = false;
				this.closeModal.nativeElement.click();
				this.loadRouteNetworks();
			});
		}
	}

	async deleteRouteNetwork(id: number) {
		this.message.errorMessage = this.globalI18n.getMessage(Module.WeightBalance, 'deleteRouteNetwork');
		this.message.errorType = 'warning';
		this.message.modalType = 'setAnswer';
		this.view.loading = true;
		await this.waitAnswer().then(async res => {
			if (res) {
				this.restApi.deleteRouteNetworks(id).then(data => {
					this.closeModal.nativeElement.click();
					this.loadRouteNetworks();
				});
			}
			this.view.loading = false;
		});
	}

	addAddress() {
		this.routeNetwork.addresses.push(new RouteNetworkAddress());
	}

	changeAddress(itemAddres: RouteNetworkAddress, newAddress: string) {
		console.log(itemAddres, newAddress);
		if (itemAddres.address !== newAddress) {
			this.routeNetwork.documents.forEach((doc: RouteNetworkDocMes) => {
				if (doc.address === itemAddres.address) {
					doc.address = newAddress;
				}
			});
			this.routeNetwork.messages.forEach((mess: RouteNetworkDocMes) => {
				if (mess.address === itemAddres.address) {
					mess.address = newAddress;
				}
			});
			console.log(this.routeNetwork);
			itemAddres.address = newAddress;
			this.routeNetwork.addresses = [...this.routeNetwork.addresses];
		}
	}

	async deleteAddress(index: number) {
		this.message.errorMessage = this.globalI18n.getMessage(Module.WeightBalance, 'deleteItem');
		this.message.errorType = 'warning';
		this.message.modalType = 'setAnswer';
		await this.waitAnswer().then(async res => {
			if (res) {
				const address = this.routeNetwork.addresses[index].address;
				this.routeNetwork.documents = this.routeNetwork.documents.filter(item => item.address !== address);
				this.routeNetwork.messages = this.routeNetwork.messages.filter(item => item.address !== address);
				this.routeNetwork.addresses.splice(index, 1);
				this.routeNetwork.addresses = [...this.routeNetwork.addresses];
			}
		});
	}

	addDocument() {
		const docs = new RouteNetworkDocMes();
		docs.docs.push(new RouteNetworkItem('notoc'));
		docs.docs.push(new RouteNetworkItem('loadsheet'));
		docs.docs.push(new RouteNetworkItem('lir'));
		console.log(this.routeNetwork);
		this.routeNetwork.documents.push(docs);
	}

	addMessage() {
		const message = new RouteNetworkDocMes();
		message.docs.push(new RouteNetworkItem('ldm'));
		message.docs.push(new RouteNetworkItem('cpm'));
		message.docs.push(new RouteNetworkItem('ucm'));
		console.log(this.routeNetwork);
		this.routeNetwork.messages.push(message);
	}

	async deleteElement(array, index: number) {
		this.message.errorMessage = this.globalI18n.getMessage(Module.WeightBalance, 'deleteItem');
		this.message.errorType = 'warning';
		this.message.modalType = 'setAnswer';
		await this.waitAnswer().then(async res => {
			if (res) {
				array.splice(index, 1);
			}
		});
	}

	validateContactArray(address: string, channelId: number) {
		return checkValidAddress(address, channelId, 'LAT');
	}

	async waitAnswer() {
		const res = await this.checkAnswer();
		return res;
	}

	checkAnswer() {
		return new Promise(resolve => {
			const interval = setInterval(() => {
				if (this.userAnswer !== null) {
					const answer = this.userAnswer;
					this.clearErrorMess();
					clearInterval(interval);
					resolve(answer);
				}
			}, 300);
		});
	}

	clearErrorMess() {
		this.message.errorMessage = '';
		this.message.errorType = '';
		this.userAnswer = null;
	}
}
