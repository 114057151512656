import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AircraftType, Airline, Airport, Tail, Flight, Document, UpdateDocumentDto } from '../models';
import { ElectronicDocument } from '../../../weight-balance-data/weight-balance';

export const WeightBalanceMobileReferenceActions = createActionGroup({
	source: 'WeightBalanceMobileReference',
	events: {
		'Get References': emptyProps(),
		'Get Reference Aircraft Type Success': props<{ data: AircraftType[] }>(),
		'Get Reference Airline Success': props<{ data: Airline[] }>(),
		'Get Reference Airport Success': props<{ data: Airport[] }>(),
		'Get Reference Tail Success': props<{ data: Tail[] }>(),
	},
});

export const WeightBalanceMobileActions = createActionGroup({
	source: 'WeightBalanceMobile',
	events: {
		'Get Flights': props<{ workspace: number }>(),
		'Get Flights Success': props<{ data: Flight[] }>(),
		'Get Electronic Documents': props<{ flightIds: number[] }>(),
		'Get Electronic Documents Success': props<{ data: ElectronicDocument[] }>(),
		'Get Load Sheet': props<{ flight: number; editions: number }>(),
		'Get Load Sheet Success': props<{ data: Document }>(),
		'Get Loading Instruction': props<{ flight: number; editions: number }>(),
		'Get Loading Instruction Success': props<{ data: Document }>(),
		'Update Documents Status': props<{ document: UpdateDocumentDto }>(),
		'Update Documents Status Success': props<{ flight_id: number }>(),
	},
});
