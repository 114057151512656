import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, isDevMode } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
// Keycloak
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './app.component';
// Routing module for router service
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Forms module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// HttpClient module for RESTful API
import { HttpClientModule } from '@angular/common/http';
// Print Module
import { NgxPrintModule } from 'ngx-print';
// File saver
import { FileSaverModule } from 'ngx-filesaver';
// Select module
import { NgSelectModule } from '@ng-select/ng-select';
// Google Charts
import { GoogleChartsModule } from 'angular-google-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
// Глобальный файл переводов для текста в ts файлах
import { GlobalI18n } from '@settings/global-i18n';
// Logger Module
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { MobileApronComponent } from './mobile-apron.component';
import { TestComponent } from './test/test.component';
import { CommunicationModuleComponent } from './communication-module/communication-module.component';
import { ScheduleModuleComponent } from './schedule-module/schedule-module.component';
import { ReferanceModuleComponent } from './referance-module/referance-module.component';
import { WorkflowModuleComponent } from './workflow-module/workflow-module.component';
import { SlotCoordinationModuleComponent } from './slot-coordination-module/slot-coordination-module.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdministrationModuleComponent } from './administration-module/administration-module.component';
import { SharedModule } from '@shared/shared.module';

import { CookieService } from 'ngx-cookie-service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReportsModuleComponent } from './reports-module/reports-module.component';
import { AmCommunicationComponent } from './administration-module/am-communication/am-communication.component';
import { AmInformationalComponent } from './administration-module/am-informational/am-informational.component';
import { AmTechprocessesComponent } from './administration-module/am-techprocesses/am-techprocesses.component';
import { AmBoardingControlComponent } from './administration-module/am-boarding-control/am-boarding-control.component';
import { BoardingControlModuleComponent } from './boarding-control-module/boarding-control-module.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ParkingPlacesComponent } from './parking-places/parking-places.component';
import { ToastrModule } from 'ngx-toastr';
import { WeightBalanceComponent } from './weight-balance/weight-balance.component';
import { AmBalanceComponent } from './administration-module/am-balance/am-balance.component';
import { AmFlightsComponent } from './administration-module/am-flights/am-flights.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { InputFormatted } from './wigets/input-formatted/input-formatted';
import { MaterialAccountingComponent } from './material-accounting/material-accounting.component';
import { DearloadSummaryTableComponent } from './weight-balance/dearload-summary-table/dearload-summary-table.component';
import { RouteNetworkComponent } from './administration-module/am-balance/route-network/route-network.component';
import { DateFormat } from './pipes/date-format';
import { FiltersComponent } from './administration-module/am-balance/filters/filters.component';
import { CompositionSeatsTableComponent } from './administration-module/am-balance/composition-seats-table/composition-table.component';
import { CrewTableComponent } from './administration-module/am-balance/crew-table/crew-table.component';
import { CrewCompositionModalComponent } from './weight-balance/crew-composition-modal/crew-composition-modal.component';
import { LostFoundModuleModule } from './lost-found-module/lost-found.module';
import { AdministrationModule } from './administration-module/administration.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WeightBalanceModule } from './features/weight-balance/weight-balance.module';
import { MasterDataModule } from './features/master-data/master-data.module';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from "./ng-zorro-antd.module";
import { HomeModule } from './features/home/home.module';
import { WeightBalanceMobileModule } from './features/weight-balance-mobile/weight-balance-mobile.module'

import { SettingsService } from "@core/services/settings/settings.service";
import { SettingsModule } from './features/settings/settings.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    MobileApronComponent,
    TestComponent,
    CommunicationModuleComponent,
    ScheduleModuleComponent,
    ReferanceModuleComponent,
    WorkflowModuleComponent,
    SlotCoordinationModuleComponent,
    AdministrationModuleComponent,
    ReportsModuleComponent,
    AmCommunicationComponent,
    AmInformationalComponent,
    AmTechprocessesComponent,
    BoardingControlModuleComponent,
    NotificationsComponent,
    AmBoardingControlComponent,
    ParkingPlacesComponent,
    WeightBalanceComponent,
    AmBalanceComponent,
    AmFlightsComponent,
    FormatNumberPipe,
    InputFormatted,
    MaterialAccountingComponent,
    DearloadSummaryTableComponent,
    RouteNetworkComponent,
    FiltersComponent,
    CompositionSeatsTableComponent,
    CrewTableComponent,
    CrewCompositionModalComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
		DateFormat,
    LostFoundModuleModule,
    MasterDataModule,
    AdministrationModule,
    SharedModule,
    HomeModule,
    WeightBalanceMobileModule,
    SettingsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
		HttpClientModule,
    NgxPrintModule,
    FileSaverModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    KeycloakAngularModule,
    NgxMatSelectSearchModule,
		NgSelectModule,
    GoogleChartsModule,
    DragDropModule,
    ToastrModule.forRoot(),
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: false,
      enableSourceMaps: true
    }),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() , connectInZone: true}),
    WeightBalanceModule,
		NgZorroAntdModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: !isDevMode(),
    // Запрос на установку приложения при успешной инициализации приложения
    // или через 30 секунд, что наступит быстрее
    registrationStrategy: 'registerWhenStable:30000'
  }),
  ],
    providers: [
      CookieService,
      GlobalI18n,
      SettingsService,
      {
        provide: APP_INITIALIZER,
        useFactory: initializer,
        multi: true,
        deps: [KeycloakService, SettingsService],
      }
  ],
  bootstrap: [AppComponent],
  exports: [
    CommunicationModuleComponent,
    MobileApronComponent,
    SlotCoordinationModuleComponent,
    ReportsModuleComponent,
  ]
})

export class AppModule { }
